import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from '@mui/material'
import RichEditor from './RichEditor'
import { useEffect, useState } from 'react'

export default function HtmlEditor({ open, onChange, html, onClose }) {
  const [content, setContent] = useState(html)
  const save = () => {
    onChange(content)
  }
  return (
    <>
      {open && (
        <Box
          sx={{
            width: '100%',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 1200,
            background: 'white',
          }}
        >
          <RichEditor value={html} onChange={(e) => setContent(e)} />
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              padding: '10px 20px',
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              background: 'white',
              borderTop: '1px solid #ccc',
            }}
          >
            <Button onClick={() => onClose()}>Cancel</Button>
            <Button variant="contained" onClick={() => save()}>
              Continue
            </Button>
          </Box>
        </Box>
      )}
    </>
  )
}
