import ReactCrop, { Crop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { useState, useRef } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Box,
  Typography,
  Slider,
  TextField,
  Stack,
  IconButton,
} from '@mui/material'
import { useEffect } from 'react'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import isMobile from 'is-mobile'

export default function ImageAdjuster({ editImage, onChange, onCancel }) {
  const imageRef = useRef(null)

  const [brightness, setBrightness] = useState(100)
  const [contrast, setContrast] = useState(100)
  const [saturation, setSaturation] = useState(100)

  const adjustImage = () => {
    const adjustedDataUrl = getAdjustedImage()
    onChange(adjustedDataUrl)
  }

  const handleSliderChange = (value, type) => {
    switch (type) {
      case 'brightness':
        setBrightness(value)
        break
      case 'contrast':
        setContrast(value)
        break
      case 'saturation':
        setSaturation(value)
        break
    }
  }

  const getStyle = () => {
    return {
      width: '100%',
      height: 'auto',
      filter: `brightness(${brightness}%) saturate(${saturation}%) contrast(${contrast}%)`,
    }
  }

  function getAdjustedImage() {
    const image = imageRef.current
    if (!image) return null

    const canvas = document.createElement('canvas')
    canvas.width = image.naturalWidth
    canvas.height = image.naturalHeight

    const ctx = canvas.getContext('2d')

    ctx.filter = `brightness(${brightness}%) saturate(${saturation}%) contrast(${contrast}%)`
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height)
    ctx.filter = 'none'
    return canvas.toDataURL('image/jpeg')
  }

  const download = () => {
    const adjustedDataUrl = getAdjustedImage()
    const downloadLink = document.createElement('a')
    downloadLink.href = adjustedDataUrl
    downloadLink.download = 'cropped-image.jpg'

    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  const reset = (type) => {
    switch (type) {
      case 'brightness':
        setBrightness(100)
        break
      case 'contrast':
        setContrast(100)
        break
      case 'saturation':
        setSaturation(100)
        break
    }
  }

  return (
    <Box
      sx={{
        width: isMobile() ? '100%' : '90%',
        display: 'flex',
        flexDirection: isMobile() ? 'column' : 'row',
      }}
    >
      <Box
        sx={{
          width: isMobile() ? '100%' : '50%',
        }}
      >
        <img ref={imageRef} src={editImage} style={getStyle()} alt="edit" />
      </Box>
      <Box
        sx={{
          padding: '20px 0px',
          width: isMobile() ? '100%' : '50%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        className="toolkit"
      >
        <Box
          sx={{
            marginBottom: isMobile() ? '10px' : '15px',
            width: isMobile() ? '100%' : '50%',
          }}
        >
          <Typography variant="subtitle2" gutterBottom>
            Brightness <strong>{brightness - 100}</strong>
          </Typography>
          <Stack spacing={2} direction="row" alignItems="center">
            <Slider
              min={0}
              max={200}
              step={1}
              value={typeof brightness === 'number' ? brightness : 0}
              onChange={(e) => handleSliderChange(e.target.value, 'brightness')}
              aria-labelledby="input-slider"
            />
            <IconButton onClick={() => reset('brightness')}>
              <RestartAltIcon />
            </IconButton>
          </Stack>
        </Box>
        <Box
          sx={{
            marginBottom: isMobile() ? '10px' : '15px',
            width: isMobile() ? '100%' : '50%',
          }}
        >
          <Typography variant="subtitle2" gutterBottom>
            Contrast <strong>{contrast - 100}</strong>
          </Typography>
          <Stack spacing={2} direction="row" alignItems="center">
            <Slider
              min={0}
              max={200}
              step={1}
              value={typeof contrast === 'number' ? contrast : 0}
              onChange={(e) => handleSliderChange(e.target.value, 'contrast')}
              aria-labelledby="input-slider"
            />
            <IconButton onClick={() => reset('contrast')}>
              <RestartAltIcon />
            </IconButton>
          </Stack>
        </Box>
        <Box
          sx={{
            marginBottom: isMobile() ? '10px' : '15px',
            width: isMobile() ? '100%' : '50%',
          }}
        >
          <Typography variant="subtitle2" gutterBottom>
            Saturation <strong>{saturation - 100}</strong>
          </Typography>
          <Stack spacing={2} direction="row" alignItems="center">
            <Slider
              min={0}
              max={200}
              step={1}
              value={typeof saturation === 'number' ? saturation : 0}
              onChange={(e) => handleSliderChange(e.target.value, 'saturation')}
              aria-labelledby="input-slider"
            />
            <IconButton onClick={() => reset('saturation')}>
              <RestartAltIcon />
            </IconButton>
          </Stack>
        </Box>

        <Button
          sx={{
            marginBottom: isMobile() ? '10px' : '15px',
            width: isMobile() ? '100%' : '50%',
          }}
          variant="contained"
          onClick={() => adjustImage()}
        >
          Apply Adjustments
        </Button>
        {/* <Button
          sx={{ marginBottom: isMobile() ? '10px' : '15px', width: isMobile() ? '100%' : '50%' }}
          variant="outlined"
          onClick={() => download()}
        >
          Download Image
        </Button> */}
        <Button
          sx={{
            marginBottom: isMobile() ? '10px' : '15px',
            width: isMobile() ? '100%' : '50%',
            color: 'gray',
          }}
          onClick={() => onCancel()}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  )
}
