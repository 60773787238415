import { Box, CircularProgress } from '@mui/material'

export default function loader() {
  return (
    <Box
      sx={{
        position: 'fixed',
        height: '100vh',
        width: '100%',
        background: 'white',
        opacity: 0.7,
        zIndex: '10000',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  )
}
