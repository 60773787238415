import { Editor } from '@tinymce/tinymce-react'
import { useState } from 'react'
import { uploadImage } from '../Services/Services'

export default function RichEditor({
  onChange,
  value,
  disabled,
  height,
  simpleToolbar,
  placeholder,
}) {
  const [val] = useState(value)

  const clearFontStyles = (editor) => {
    editor.undoManager.transact(() => {
      let content = editor.getContent({ format: 'html' })
      const tempDiv = document.createElement('div')
      tempDiv.innerHTML = content

      tempDiv.querySelectorAll('*').forEach((el) => {
        el.style.fontFamily = ''
        // Reset more styles if needed
      })

      editor.setContent(tempDiv.innerHTML, { format: 'html' })
    })
  }

  const imageUploadHandler = (blobInfo) => {
    return new Promise(async (resolve, reject) => {
      try {
        const file = blobInfo.blob()
        const response = await uploadImage(file)
        resolve(response.url) // Use the URL from your API response
      } catch (e) {
        reject(e)
      }
    })
  }

  return (
    <Editor
      onEditorChange={disabled ? () => {} : onChange}
      tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
      initialValue={val}
      disabled={disabled}
      init={{
        selector: 'textarea#file-picker',
        placeholder: placeholder ? placeholder : 'Write your content here...',
        min_height: height ? height : window.innerHeight - 70,
        max_height: height ? height : window.innerHeight - 50,
        menubar: false,
        plugins: [
          'preview',
          'image',
          'wordcount',
          'table',
          'link',
          'lists',
          'autosave',
          'autoresize',
        ],
        branding: false,
        images_upload_handler: imageUploadHandler,
        images_reuse_filename: true,
        toolbar: disabled
          ? ''
          : simpleToolbar
          ? 'bold italic underline strikethrough bullist link'
          : 'bold italic underline strikethrough | fontsize blocks | alignleft aligncenter alignright alignjustify | numlist bullist | forecolor backcolor removeformat | image link table | customClearFontStyles',
        setup: (editor) => {
          editor.ui.registry.addButton('customClearFontStyles', {
            text: 'Reset Font',
            onAction: () => clearFontStyles(editor),
          })
        },
      }}
    />
  )
}
