import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Typography,
} from '@mui/material'
import { useState } from 'react'
export default function ImageGrid({ item }) {
  const [openImageConf, setOpenImageConf] = useState({})

  const handleOpen = (image) => {
    setOpenImageConf({
      open: true,
      image: image.url,
    })
  }

  const handleClose = () => {
    setOpenImageConf((p) => {
      return {
        ...p,
        open: false,
      }
    })
  }
  return (
    <>
      <Grid container spacing={2}>
        {item.map((image) => (
          <Grid item xs={12} md={6} lg={4} key={image.id}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: '200px',
                cursor: 'pointer',
              }}
              onClick={() => handleOpen(image)}
            >
              <img
                style={{
                  height: '100%',
                  width: '100%',
                  objectFit: 'contain',
                }}
                src={image.url}
                alt={image.alt}
              />
            </Box>
            <Typography variant="body1" color="initial">
              {image.alt}
            </Typography>
          </Grid>
        ))}
      </Grid>

      <Dialog open={openImageConf?.open} onClose={handleClose} maxWidth="md">
        <DialogContent sx={{ padding: '2px' }}>
          <Paper
            elevation={0}
            style={{ textAlign: 'center' }}
            sx={{ height: `${window.innerHeight - 200}px` }}
          >
            <img
              src={openImageConf.image}
              alt=""
              style={{ height: '99%', width: '99%', objectFit: 'contain' }}
            />
          </Paper>
        </DialogContent>
      </Dialog>
    </>
  )
}
