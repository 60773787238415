import { useQuery } from 'react-query'
import { Link, useParams } from 'react-router-dom'
import {
  getGalleryItemDetails,
  isMobile,
  publishGalleryItem,
  publishGalleryItemChanges,
  removePublishedAlbum,
  republishAlbum,
} from '../Services/Services'
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid,
  FormLabel,
  Box,
  Chip,
  Alert,
  FormControl,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
} from '@mui/material'
import RichEditor from '../Common/RichEditor'
import EditIcon from '@mui/icons-material/Edit'
import InfoIcon from '@mui/icons-material/Info'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'
import EditCalendarRoundedIcon from '@mui/icons-material/EditCalendarRounded'
import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded'
import SendRoundedIcon from '@mui/icons-material/SendRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import LaunchIcon from '@mui/icons-material/Launch'
import moment from 'moment/moment'
import { secondaryColor, websiteUrl } from '../constants'
import { History } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import useNotify from '../Hooks/useNotify'
import PageLoader from '../Common/PageLoader'
import Loader from '../Common/Loader'
import Container from '../Common/Container'
import ImageGrid from '../Common/ImageGrid'
import HtmlViewer from '../Common/HtmlViewer'
import { useNavigate } from 'react-router-dom'
export default function GalleryDetails() {
  const navigate = useNavigate()

  const { notifySuccess, notifyError } = useNotify()
  const params = useParams()
  const [alertConf, setAlertConf] = useState({})
  const [reqInProgress, setReqInProgress] = useState(false)

  const { isLoading, data, error, refetch } = useQuery(
    ['galleryItemDetails', params.id],
    () => getGalleryItemDetails(params.id)
  )

  useEffect(() => {
    if (error) {
      notifyError('Some Error Occured!')
    }
  }, [error, notifyError])

  if (isLoading) {
    return <PageLoader />
  }

  const publish = async () => {
    if (data.published) {
      return
    }
    setReqInProgress(true)
    try {
      await publishGalleryItem(data._id)
      notifySuccess('Album Published Successfully!')
    } catch (e) {
      if (e?.response?.data?.message) {
        notifyError(`${e?.response?.data?.message}`)
      }
    } finally {
      setReqInProgress(false)
    }
    refetch()
  }

  const publishChanges = async () => {
    if (!data.published) {
      return
    }
    setReqInProgress(true)
    try {
      await publishGalleryItemChanges(data._id)
      notifySuccess(`Changes Published Successfully!`)
    } catch (e) {
      if (e?.response?.data?.message) {
        notifyError(`${e?.response?.data?.message}`)
      }
    } finally {
      setReqInProgress(false)
    }
    refetch()
  }

  const removeAlbum = async () => {
    if (!data.published) {
      return
    }
    setReqInProgress(true)
    try {
      await removePublishedAlbum(data._id)
      notifySuccess(`Album Removed Successfully!`)
    } catch (e) {
      if (e?.response?.data?.message) {
        notifyError(`${e?.response?.data?.message}`)
      }
    } finally {
      setReqInProgress(false)
    }
    refetch()
  }

  const republishPrevAlbum = async () => {
    if (!data.published) {
      return
    }
    setReqInProgress(true)
    try {
      await republishAlbum(data._id)
      notifySuccess(`Album Re-Published Successfully!`)
    } catch (e) {
      if (e?.response?.data?.message) {
        notifyError(`${e?.response?.data?.message}`)
      }
    } finally {
      setReqInProgress(false)
    }
    refetch()
  }

  const republishAlbumLatest = async () => {
    if (!data.published) {
      return
    }
    setReqInProgress(true)
    try {
      await publishGalleryItemChanges(data._id)
      notifySuccess(`Album Re-Published with latest changes successfully!`)
    } catch (e) {
      if (e?.response?.data?.message) {
        notifyError(`${e?.response?.data?.message}`)
      }
    } finally {
      setReqInProgress(false)
    }
    refetch()
  }

  return (
    <>
      {reqInProgress && <PageLoader dark={true} message={'Please wait'} />}
      <Container>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5" color="initial" sx={{ my: 2 }}>
            Album Details
          </Typography>
          <Button
            variant="contained"
            sx={{
              background: secondaryColor,
              '&:hover': {
                background: '#a3dcfc',
              },
              color: 'black',
            }}
            onClick={() => {
              navigate(`/gallery/${data._id}/edit`)
            }}
          >
            Edit
          </Button>
        </Box>
        {data && (
          <Grid container spacing={1}>
            <Grid item xs={12} lg={3}>
              <Box sx={!isMobile() && { position: 'sticky', top: '75px' }}>
                <Card sx={{ mb: 1 }}>
                  <CardContent>
                    {data?.published && (
                      <>
                        <Box sx={{ mb: 1 }}>
                          <FormControl fullWidth>
                            {data.published.live && (
                              <Chip
                                label={`PUBLISHED | VERSION ${data.published.version}`}
                                color="success"
                              />
                            )}
                            {!data.published.live && (
                              <Chip
                                label={`REMOVED | LAST VERSION ${data.published.version}`}
                                color="error"
                              />
                            )}
                          </FormControl>
                        </Box>
                        {data.published.live && (
                          <Box sx={{ mb: 0 }}>
                            <FormControl fullWidth>
                              <Button
                                variant="outlined"
                                color="error"
                                startIcon={<DeleteRoundedIcon />}
                                onClick={() =>
                                  setAlertConf({
                                    open: true,
                                    title: 'Remove Alblum',
                                    message:
                                      'Are you sure you want to Remove this album from website?',
                                    onSubmit: removeAlbum,
                                  })
                                }
                              >
                                Remove Album
                              </Button>
                            </FormControl>
                          </Box>
                        )}
                        {!data.published.live && (
                          <Box sx={{ mb: 0 }}>
                            <FormControl fullWidth>
                              <Button
                                variant="outlined"
                                color="success"
                                startIcon={<SendRoundedIcon />}
                                onClick={() =>
                                  setAlertConf({
                                    open: true,
                                    title: 'Re-Publish Album',
                                    message:
                                      'Are you sure you want to publish this album with last published version?',
                                    onSubmit: republishPrevAlbum,
                                  })
                                }
                              >
                                {`Re-publish Album (V-${data.published.version})`}
                              </Button>
                            </FormControl>
                          </Box>
                        )}
                      </>
                    )}

                    {!data?.published && (
                      <>
                        <Box sx={{ mb: 0 }}>
                          <FormControl fullWidth>
                            <Chip label={`SAVED AS DARFT`} color="info" />
                          </FormControl>
                        </Box>
                      </>
                    )}
                  </CardContent>
                </Card>
                <Card sx={{ mb: 1 }}>
                  {data && (
                    <CardContent>
                      <Box sx={{ mb: 2 }}>
                        <Box
                          sx={{ mb: 1, display: 'flex', alignItems: 'center' }}
                        >
                          <InfoIcon />
                          &nbsp;
                          <FormLabel>Current Version</FormLabel>
                        </Box>
                        <Box sx={{ mb: 1 }}>
                          <Typography variant="h4" color="primary">
                            {data.version}
                          </Typography>
                        </Box>
                      </Box>

                      <Box sx={{ mb: 2 }}>
                        <Box
                          sx={{ mb: 1, display: 'flex', alignItems: 'center' }}
                        >
                          <CalendarMonthRoundedIcon />
                          &nbsp;
                          <FormLabel>Created On</FormLabel>
                        </Box>
                        <Box sx={{ mb: 1 }}>
                          <Typography variant="body1" color="initial">
                            {moment(data.createdAt).format(
                              'Do MMM YYYY | hh:mm:ss A'
                            )}
                          </Typography>
                        </Box>
                      </Box>

                      <Box sx={{ mb: 2 }}>
                        <Box
                          sx={{ mb: 1, display: 'flex', alignItems: 'center' }}
                        >
                          <EditCalendarRoundedIcon />
                          &nbsp;
                          <FormLabel>Last Updated on</FormLabel>
                        </Box>
                        <Box sx={{ mb: 1 }}>
                          <Typography variant="body1" color="initial">
                            {moment(data.updatedAt).format(
                              'Do MMM YYYY | hh:mm:ss A'
                            )}
                          </Typography>
                        </Box>
                      </Box>

                      {data.published && (
                        <Box sx={{ mb: 2 }}>
                          <Box
                            sx={{
                              mb: 1,
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <EventAvailableRoundedIcon />
                            &nbsp;
                            <FormLabel>Last Publish update</FormLabel>
                          </Box>
                          <Box sx={{ mb: 1 }}>
                            <Typography variant="body1" color="initial">
                              {moment(data.published.publishedAt).format(
                                'Do MMM YYYY | hh:mm:ss A'
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      )}

                      {data.published &&
                        data.published.version < data.version && (
                          <Box sx={{ mb: 2 }}>
                            <Alert severity="warning" color="warning">
                              Album contains{' '}
                              {data.published.live ? 'unpublished' : 'new'}{' '}
                              changes
                            </Alert>
                          </Box>
                        )}

                      {data.published &&
                        data.published.version < data.version &&
                        data.published.live && (
                          <Box sx={{ mb: 2 }}>
                            <FormControl fullWidth>
                              <Button
                                variant="contained"
                                color="primary"
                                startIcon={<SendRoundedIcon />}
                                onClick={() =>
                                  setAlertConf({
                                    open: true,
                                    title: 'Publish Changes',
                                    message:
                                      'Are you sure you want to publish new changes?',
                                    onSubmit: publishChanges,
                                  })
                                }
                              >
                                PUBLISH CHANGES
                              </Button>
                            </FormControl>
                          </Box>
                        )}

                      {data.published &&
                        data.published.version < data.version &&
                        !data.published.live && (
                          <Box sx={{ mb: 2 }}>
                            <FormControl fullWidth>
                              <Button
                                variant="contained"
                                color="success"
                                startIcon={<SendRoundedIcon />}
                                onClick={() =>
                                  setAlertConf({
                                    open: true,
                                    title: 'Publish Album',
                                    message:
                                      'Are you sure you want to Re-Publish this album?',
                                    onSubmit: republishAlbumLatest,
                                  })
                                }
                              >
                                RE-PUBLISH CHANGES
                              </Button>
                            </FormControl>
                          </Box>
                        )}

                      {!data.published && (
                        <Box sx={{ mb: 2 }}>
                          <FormControl fullWidth>
                            <Button
                              variant="contained"
                              color="primary"
                              startIcon={<SendRoundedIcon />}
                              onClick={() =>
                                setAlertConf({
                                  open: true,
                                  title: 'Publish Album',
                                  message:
                                    'Are you sure you want to publish this album?',
                                  onSubmit: publish,
                                })
                              }
                            >
                              PUBLISH ALBUM
                            </Button>
                          </FormControl>
                        </Box>
                      )}
                    </CardContent>
                  )}
                </Card>
              </Box>
            </Grid>
            <Grid item xs={12} lg={9}>
              <Card sx={{ mb: 2 }}>
                <CardHeader title={'Images'}></CardHeader>
                <CardContent>
                  <ImageGrid item={data.images} />
                </CardContent>
              </Card>

              <Card sx={{ mb: 2 }}>
                <CardHeader title="Caption" />
                <CardContent>
                  <HtmlViewer html={data.caption} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
        <Dialog
          open={alertConf.open}
          onClose={() => {}}
          fullWidth={true}
          maxWidth={'sm'}
        >
          <DialogTitle>{alertConf.title}</DialogTitle>
          <DialogContent>
            <Typography variant="body2" color="initial">
              {alertConf.message}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAlertConf({})}>Cancel</Button>
            <Button
              variant="contained"
              onClick={() => {
                alertConf.onSubmit()
                setAlertConf({})
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  )
}
