import { Box, TextField, InputAdornment } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import debounce from 'lodash.debounce'

function PageSearch({ onSearch, label }) {
  const handleDebouncedSearch = debounce((value) => {
    if (onSearch) {
      onSearch(value)
    }
  }, 800)

  return (
    <Box
      sx={{
        paddingBottom: 0,
      }}
    >
      <TextField
        sx={{
          background: 'white',
        }}
        fullWidth
        placeholder={label}
        id="fullWidth"
        type="search"
        onChange={(e) => {
          handleDebouncedSearch(e.target.value)
        }}
        InputProps={{
          sx: {
            borderRadius: '24px',
          },
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  )
}

export default PageSearch
