import { useQuery } from 'react-query'
import { Link, useParams } from 'react-router-dom'
import {
  getBlogDetails,
  isMobile,
  publishBlog,
  publishBlogChanges,
  removeBlog,
  republishBlog,
  republishBlogLatest,
} from '../Services/Services'
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  IconButton,
  Grid,
  FormLabel,
  Box,
  Chip,
  Alert,
  FormControl,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material'
import RichEditor from '../Common/RichEditor'
import EditIcon from '@mui/icons-material/Edit'
import InfoIcon from '@mui/icons-material/Info'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'
import EditCalendarRoundedIcon from '@mui/icons-material/EditCalendarRounded'
import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded'
import SendRoundedIcon from '@mui/icons-material/SendRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import LaunchIcon from '@mui/icons-material/Launch'
import moment from 'moment/moment'
import { websiteUrl } from '../constants'
import { History } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import useNotify from '../Hooks/useNotify'
import PageLoader from '../Common/PageLoader'
import Loader from '../Common/Loader'
import Container from '../Common/Container'
import HtmlViewer from '../Common/HtmlViewer'

export default function BlogDetails() {
  const { notifySuccess, notifyError } = useNotify()
  const params = useParams()
  const [alertConf, setAlertConf] = useState({})
  const [reqInProgress, setReqInProgress] = useState(false)

  const [random] = useState(Math.random())
  const { isLoading, data, error, refetch } = useQuery(
    ['blogDetails', params.id, random],
    () => getBlogDetails(params.id)
  )

  useEffect(() => {
    if (error) {
      notifyError('Some Error Occured!')
    }
  }, [error, notifyError])

  if (isLoading) {
    return <PageLoader />
  }

  const publish = async () => {
    if (data.publishedBlog) {
      return
    }
    setReqInProgress(true)
    try {
      await publishBlog(data._id)
      notifySuccess('Blog Published Successfully!')
    } catch (e) {
      if (e?.response?.data?.message) {
        notifyError(`${e?.response?.data?.message}`)
      }
    } finally {
      setReqInProgress(false)
    }
    refetch()
  }

  const publishChanges = async () => {
    if (!data.publishedBlog) {
      return
    }
    setReqInProgress(true)
    try {
      await publishBlogChanges(data._id)
      notifySuccess(`Changes Published Successfully!`)
    } catch (e) {
      if (e?.response?.data?.message) {
        notifyError(`${e?.response?.data?.message}`)
      }
    } finally {
      setReqInProgress(false)
    }
    refetch()
  }

  const removeCurrBlog = async () => {
    if (!data.publishedBlog) {
      return
    }
    setReqInProgress(true)
    try {
      await removeBlog(data._id)
      notifySuccess(`Blog Removed Successfully!`)
    } catch (e) {
      if (e?.response?.data?.message) {
        notifyError(`${e?.response?.data?.message}`)
      }
    } finally {
      setReqInProgress(false)
    }
    refetch()
  }

  const republishPrevBlog = async () => {
    if (!data.publishedBlog) {
      return
    }
    setReqInProgress(true)
    try {
      await republishBlog(data._id)
      notifySuccess(`Blog Re-Published Successfully!`)
    } catch (e) {
      if (e?.response?.data?.message) {
        notifyError(`${e?.response?.data?.message}`)
      }
    } finally {
      setReqInProgress(false)
    }
    refetch()
  }

  const republishNewBlog = async () => {
    if (!data.publishedBlog) {
      return
    }
    setReqInProgress(true)
    try {
      await republishBlogLatest(data._id)
      notifySuccess(`Blog Re-Published with latest changes successfully!`)
    } catch (e) {
      if (e?.response?.data?.message) {
        notifyError(`${e?.response?.data?.message}`)
      }
    } finally {
      setReqInProgress(false)
    }
    refetch()
  }

  return (
    <Container>
      {reqInProgress && <Loader />}
      {/* <Typography variant="h5" color="initial" sx={{ my: 2 }}>
        Blog Details
      </Typography> */}
      {data && (
        <Grid container spacing={1}>
          <Grid item xs={12} lg={3}>
            <Box sx={!isMobile() && { position: 'sticky', top: '75px' }}>
              <Card sx={{ mb: 1 }}>
                <CardContent>
                  {data?.publishedBlog && (
                    <>
                      <Box sx={{ mb: 1 }}>
                        <FormControl fullWidth>
                          {data.publishedBlog.live && (
                            <Chip
                              label={`PUBLISHED | VERSION ${data.publishedBlog.version}`}
                              color="success"
                            />
                          )}
                          {!data.publishedBlog.live && (
                            <Chip
                              label={`REMOVED | LAST VERSION ${data.publishedBlog.version}`}
                              color="error"
                            />
                          )}
                        </FormControl>
                      </Box>
                      {data.publishedBlog.live && (
                        <Box sx={{ mb: 1 }}>
                          <FormControl fullWidth>
                            <Button
                              onClick={() =>
                                window.open(
                                  `${websiteUrl}/blogs/${data.publishedBlog.data.slug}`
                                )
                              }
                              variant="outlined"
                              color="success"
                              startIcon={<LaunchIcon />}
                            >
                              Open published Blog
                            </Button>
                          </FormControl>
                        </Box>
                      )}
                      {data.publishedBlog.live && (
                        <Box sx={{ mb: 0 }}>
                          <FormControl fullWidth>
                            <Button
                              variant="outlined"
                              color="error"
                              startIcon={<DeleteRoundedIcon />}
                              onClick={() =>
                                setAlertConf({
                                  open: true,
                                  title: 'Remove Blog',
                                  message:
                                    'Are you sure you want to Remove this blog from website?',
                                  onSubmit: removeCurrBlog,
                                })
                              }
                            >
                              Remove blog
                            </Button>
                          </FormControl>
                        </Box>
                      )}
                      {!data.publishedBlog.live && (
                        <Box sx={{ mb: 0 }}>
                          <FormControl fullWidth>
                            <Button
                              variant="outlined"
                              color="success"
                              startIcon={<SendRoundedIcon />}
                              onClick={() =>
                                setAlertConf({
                                  open: true,
                                  title: 'Re-Publish Blog',
                                  message:
                                    'Are you sure you want to publish this blog with last published version?',
                                  onSubmit: republishPrevBlog,
                                })
                              }
                            >
                              {`Re-publish blog (V-${data.publishedBlog.version})`}
                            </Button>
                          </FormControl>
                        </Box>
                      )}
                    </>
                  )}

                  {!data?.publishedBlog && (
                    <>
                      <Box sx={{ mb: 0 }}>
                        <FormControl fullWidth>
                          <Chip label={`SAVED AS DARFT`} color="info" />
                        </FormControl>
                      </Box>
                    </>
                  )}
                </CardContent>
              </Card>
              <Card sx={{ mb: 1 }}>
                {data && (
                  <CardContent>
                    <Box sx={{ mb: 2 }}>
                      <Box
                        sx={{ mb: 1, display: 'flex', alignItems: 'center' }}
                      >
                        <InfoIcon />
                        &nbsp;
                        <FormLabel>Current Version</FormLabel>
                      </Box>
                      <Box sx={{ mb: 1 }}>
                        <Typography variant="h4" color="primary">
                          {data.version}
                        </Typography>
                      </Box>
                    </Box>

                    <Box sx={{ mb: 2 }}>
                      <Box
                        sx={{ mb: 1, display: 'flex', alignItems: 'center' }}
                      >
                        <CalendarMonthRoundedIcon />
                        &nbsp;
                        <FormLabel>Created On</FormLabel>
                      </Box>
                      <Box sx={{ mb: 1 }}>
                        <Typography variant="body1" color="initial">
                          {moment(data.createdAt).format(
                            'Do MMM YYYY | hh:mm:ss A'
                          )}
                        </Typography>
                      </Box>
                    </Box>

                    <Box sx={{ mb: 2 }}>
                      <Box
                        sx={{ mb: 1, display: 'flex', alignItems: 'center' }}
                      >
                        <EditCalendarRoundedIcon />
                        &nbsp;
                        <FormLabel>Last Updated on</FormLabel>
                      </Box>
                      <Box sx={{ mb: 1 }}>
                        <Typography variant="body1" color="initial">
                          {moment(data.updatedAt).format(
                            'Do MMM YYYY | hh:mm:ss A'
                          )}
                        </Typography>
                      </Box>
                    </Box>

                    {data.publishedBlog && (
                      <Box sx={{ mb: 2 }}>
                        <Box
                          sx={{ mb: 1, display: 'flex', alignItems: 'center' }}
                        >
                          <EventAvailableRoundedIcon />
                          &nbsp;
                          <FormLabel>Last Publish update</FormLabel>
                        </Box>
                        <Box sx={{ mb: 1 }}>
                          <Typography variant="body1" color="initial">
                            {moment(data.publishedBlog.updatedAt).format(
                              'Do MMM YYYY | hh:mm:ss A'
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    )}

                    {data.publishedBlog &&
                      data.publishedBlog.version < data.version && (
                        <Box sx={{ mb: 2 }}>
                          <Alert severity="warning" color="warning">
                            Blog contains{' '}
                            {data.publishedBlog.live ? 'unpublished' : 'new'}{' '}
                            changes
                          </Alert>
                        </Box>
                      )}

                    {data.publishedBlog &&
                      data.publishedBlog.version < data.version &&
                      data.publishedBlog.live && (
                        <Box sx={{ mb: 2 }}>
                          <FormControl fullWidth>
                            <Button
                              variant="contained"
                              color="primary"
                              startIcon={<SendRoundedIcon />}
                              onClick={() =>
                                setAlertConf({
                                  open: true,
                                  title: 'Publish Changes',
                                  message:
                                    'Are you sure you want to publish new changes?',
                                  onSubmit: publishChanges,
                                })
                              }
                            >
                              PUBLISH CHANGES
                            </Button>
                          </FormControl>
                        </Box>
                      )}

                    {data.publishedBlog &&
                      data.publishedBlog.version < data.version &&
                      !data.publishedBlog.live && (
                        <Box sx={{ mb: 2 }}>
                          <FormControl fullWidth>
                            <Button
                              variant="contained"
                              color="success"
                              startIcon={<SendRoundedIcon />}
                              onClick={() =>
                                setAlertConf({
                                  open: true,
                                  title: 'Publish Blog',
                                  message:
                                    'Are you sure you want to Re-Publish this blog?',
                                  onSubmit: republishNewBlog,
                                })
                              }
                            >
                              RE-PUBLISH CHANGES
                            </Button>
                          </FormControl>
                        </Box>
                      )}

                    {!data.publishedBlog && (
                      <Box sx={{ mb: 2 }}>
                        <FormControl fullWidth>
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={<SendRoundedIcon />}
                            onClick={() =>
                              setAlertConf({
                                open: true,
                                title: 'Publish Blog',
                                message:
                                  'Are you sure you want to publish this blog?',
                                onSubmit: publish,
                              })
                            }
                          >
                            PUBLISH BLOG
                          </Button>
                        </FormControl>
                      </Box>
                    )}
                  </CardContent>
                )}
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} lg={9}>
            <Card sx={{ mb: 2 }}>
              <CardHeader
                title="Title & Banner Details"
                action={
                  <Link to={`/blogs/${data?._id}/edit/0`}>
                    <IconButton aria-label="settings">
                      <EditIcon />
                    </IconButton>
                  </Link>
                }
              />
              {data && (
                <CardContent>
                  <Box sx={{ mb: 2 }}>
                    {/* <Box sx={{ mb: 1 }}>
                    <FormLabel>Title</FormLabel>
                  </Box> */}
                    <Box sx={{ mb: 0 }}>
                      <Typography
                        variant="h4"
                        color="initial"
                        sx={{ fontWeight: 'bold' }}
                      >
                        {data?.title}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ mb: 0 }}>
                    {/* <Box sx={{ mb: 1 }}>
                    <FormLabel>Banner</FormLabel>
                  </Box> */}
                    <Box
                      sx={{
                        // height: '300px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <img
                        src={data.banner || '../default.png'}
                        alt=""
                        style={{ width: '100%', borderRadius: '10px' }}
                      />
                    </Box>
                  </Box>
                </CardContent>
              )}
            </Card>

            <Card sx={{ mb: 2 }}>
              <CardHeader
                title="Content Details"
                action={
                  <Link to={`/blogs/${data?._id}/edit/1`}>
                    <IconButton aria-label="settings">
                      <EditIcon />
                    </IconButton>
                  </Link>
                }
              />
              {data && (
                <CardContent>
                  <HtmlViewer html={data.content} />
                </CardContent>
              )}
            </Card>

            <Card sx={{ mb: 2 }}>
              <CardHeader
                title="Meta Info & Other Details"
                action={
                  <Link to={`/blogs/${data?._id}/edit/2`}>
                    <IconButton aria-label="settings">
                      <EditIcon />
                    </IconButton>
                  </Link>
                }
              />
              {data && (
                <CardContent>
                  <Box sx={{ mb: 2 }}>
                    <Box>
                      <FormLabel>Blog Date:</FormLabel>
                    </Box>
                    <Typography variant="body2" color="initial">
                      {moment(data?.blogDate).format('Do MMM YYYY')}
                    </Typography>
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <Box>
                      <FormLabel>Tags / Keywords:</FormLabel>
                    </Box>
                    <Typography variant="body2" color="initial">
                      {data?.tags.join(', ')}
                    </Typography>
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <Box>
                      <FormLabel>Language:</FormLabel>
                    </Box>
                    <Typography variant="body2" color="initial">
                      {data?.lang}
                    </Typography>
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <Box>
                      <FormLabel>Slug / URL:</FormLabel>
                    </Box>
                    <Typography
                      variant="body2"
                      color="initial"
                      component={'span'}
                      sx={{
                        fontFamily: 'monospace',
                      }}
                    >
                      {`${data?.slug}`}
                    </Typography>
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <Box>
                      <FormLabel>Meta Title:</FormLabel>
                    </Box>
                    <Typography variant="body2" color="initial">
                      {data?.metaTitle}
                    </Typography>
                  </Box>

                  <Box>
                    <Box>
                      <FormLabel>Meta Description:</FormLabel>
                    </Box>
                    <Typography variant="body2" color="initial">
                      {data?.metaDescription}
                    </Typography>
                  </Box>
                </CardContent>
              )}
            </Card>

            <Card sx={{ mb: 2 }}>
              <CardHeader title="Previous Published URLs" />
              {data && (
                <CardContent>
                  {data.publishedBlog?.data.oldUrls?.map((u, index) => (
                    <Box sx={{ mb: 2 }} key={index}>
                      <Typography variant="body2" color="initial">
                        <a
                          href={`${websiteUrl}/blogs/${u}`}
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            textDecoration: 'none',
                            color: 'black',
                            fontFamily: 'monospace',
                          }}
                        >
                          {`${websiteUrl}/blogs/${u}`}
                        </a>
                      </Typography>
                    </Box>
                  ))}
                </CardContent>
              )}
            </Card>
          </Grid>
        </Grid>
      )}
      <Dialog
        open={alertConf.open}
        onClose={() => {}}
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogTitle>{alertConf.title}</DialogTitle>
        <DialogContent>
          <Typography variant="body2" color="initial">
            {alertConf.message}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAlertConf({})}>Cancel</Button>
          <Button
            variant="contained"
            onClick={() => {
              alertConf.onSubmit()
              setAlertConf({})
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}
