import ReactCrop, { Crop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { useState, useRef } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Box,
  Typography,
  Paper,
  TextField,
} from '@mui/material'
import isMobile from 'is-mobile'

export default function ImageCropper({ editImage, onChange, onCancel }) {
  const imageRef = useRef(null)
  const [crop, setCrop] = useState({
    unit: 'px',
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  })
  const [dimension, setDimensions] = useState({ width: 0, height: 0 })

  const imageLoaded = (event) => {
    const img = event.target
    setDimensions({ width: img.width, height: img.height })
    setCrop({
      unit: 'px',
      width: Math.ceil(img.width / 2),
      height: Math.ceil(img.height / 2),
      x: Math.ceil(img.width / 4),
      y: Math.ceil(img.height / 4),
    })
  }

  const cropImage = () => {
    const croppedDataURL = getCroppedImage()
    onChange(croppedDataURL)
    setCrop({})
  }

  function getCroppedImage() {
    const image = imageRef.current
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const canvas = document.createElement('canvas')

    canvas.width = Math.ceil(crop.width * scaleX)
    canvas.height = Math.ceil(crop.height * scaleY)

    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    )
    return canvas.toDataURL('image/jpeg')
  }

  const download = () => {
    const croppedDataURL = getCroppedImage()
    const downloadLink = document.createElement('a')
    downloadLink.href = croppedDataURL
    downloadLink.download = 'cropped-image.jpg'

    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  return (
    <Box
      sx={{
        width: isMobile() ? '100%' : '90%',
        display: 'flex',
        flexDirection: isMobile() ? 'column' : 'row',
      }}
    >
      <Box
        sx={{
          width: isMobile() ? '100%' : '50%',
        }}
      >
        <ReactCrop
          crop={crop}
          onChange={(c) => setCrop(c)}
          keepSelection={true}
        >
          <img
            ref={imageRef}
            onLoad={imageLoaded}
            src={editImage}
            style={{
              width: '100%',
              height: 'auto',
            }}
            alt="edit"
          />
        </ReactCrop>
      </Box>
      <Box
        sx={{
          padding: '20px 0px',
          width: isMobile() ? '100%' : '50%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        className="toolkit"
      >
        <TextField
          type="number"
          sx={{
            marginBottom: isMobile() ? '10px' : '15px',
            width: isMobile() ? '100%' : '50%',
          }}
          id="outlined-basic"
          label="Width"
          variant="outlined"
          value={Math.ceil(crop.width)}
          onChange={(e) => {
            setCrop({ ...crop, width: e.target.value })
          }}
        />
        <TextField
          type="number"
          sx={{
            marginBottom: isMobile() ? '10px' : '15px',
            width: isMobile() ? '100%' : '50%',
          }}
          id="outlined-basic"
          label="Height"
          variant="outlined"
          value={Math.ceil(crop.height)}
          onChange={(e) => {
            setCrop({ ...crop, height: e.target.value })
          }}
        />
        <TextField
          type="number"
          sx={{
            marginBottom: isMobile() ? '10px' : '15px',
            width: isMobile() ? '100%' : '50%',
          }}
          id="outlined-basic"
          label="Position X"
          variant="outlined"
          value={Math.ceil(crop.x)}
          onChange={(e) => {
            setCrop({ ...crop, x: e.target.value })
          }}
        />
        <TextField
          type="number"
          sx={{
            marginBottom: isMobile() ? '10px' : '15px',
            width: isMobile() ? '100%' : '50%',
          }}
          id="outlined-basic"
          label="Position Y"
          variant="outlined"
          value={Math.ceil(crop.y)}
          onChange={(e) => {
            setCrop((prev) => {
              return {
                ...prev,
                y: e.target.value,
              }
            })
          }}
        />
        <Button
          sx={{
            marginBottom: isMobile() ? '10px' : '15px',
            width: isMobile() ? '100%' : '50%',
          }}
          variant="contained"
          onClick={() => cropImage()}
        >
          Crop Image
        </Button>
        <Button
          sx={{
            marginBottom: isMobile() ? '10px' : '15px',
            width: isMobile() ? '100%' : '50%',
            color: 'gray',
          }}
          onClick={() => onCancel()}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  )
}
