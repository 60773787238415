import { createContext, useState, useMemo } from "react";
import mobile from "is-mobile";

export const Context = createContext();

function UserContext({ children }) {
  const [currUser, setCurrUser] = useState(null);
  const isMobile = useMemo(function () {
    return mobile();
  }, []);
  return (
    <Context.Provider value={{ currUser, setCurrUser, isMobile }}>
      {children}
    </Context.Provider>
  );
}

export default UserContext;
