import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  CardActions,
  IconButton,
  Fab,
  Pagination,
  Chip,
  Button,
  Tooltip,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import PageSearch from '../Common/PageSearch'
import { useQuery } from 'react-query'
import { getBlogs, isMobile } from '../Services/Services'
import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import moment from 'moment/moment'
import { WarningRounded } from '@mui/icons-material'
import { secondaryColor } from '../constants'
import PageLoader from '../Common/PageLoader'
import useNotify from '../Hooks/useNotify'
import Container from '../Common/Container'

export default function BlogsListPage() {
  const navigate = useNavigate()
  const { notifyError } = useNotify()

  const [pageNumber, setPageNumber] = useState(1)
  const PAGE_SIZE = 15
  const [searchString, setSearchString] = useState('')

  const { isLoading, error, data } = useQuery(
    ['blogs', pageNumber, searchString],
    () => getBlogs(pageNumber, PAGE_SIZE, searchString),
    {
      keepPreviousData: true,
    }
  )

  useEffect(() => {
    if (error) {
      notifyError('Some Error Occured!')
    }
  }, [error, notifyError])

  if (isLoading) {
    return <PageLoader />
  }

  const goToNewBlog = () => {
    navigate('/blogs/new')
  }

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          my: 2,
        }}
      >
        <Typography variant="h5" color="initial">
          All Blogs Post
        </Typography>

        <Button
          onClick={goToNewBlog}
          variant="contained"
          startIcon={<AddIcon />}
          sx={{
            background: secondaryColor,
            color: 'black',
            '&:hover': {
              background: '#a3dcfc',
            },
          }}
        >
          Blog
        </Button>
      </Box>
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Box sx={{ my: 1, position: 'relative' }}>
            <PageSearch
              label={'Search Blog Title'}
              onSearch={setSearchString}
            />
          </Box>
        </CardContent>
      </Card>
      {data?.data?.length > 0 && (
        <Grid container spacing={2}>
          {data?.data?.map((blog) => (
            <Grid item xs={12} md={6} lg={4} key={blog._id}>
              <Link
                style={{ color: 'black', textDecoration: 'none' }}
                to={`/blogs/${blog._id}`}
              >
                <Card sx={{ maxWidth: 'auto', height: '100%' }}>
                  <CardMedia
                    component="img"
                    alt=""
                    height="230"
                    image={blog.banner || '../default.png'}
                  />
                  <CardContent sx={{ position: 'relative' }}>
                    <Box sx={{ my: 1 }}></Box>
                    <Typography variant="body2" color="text.secondary">
                      {moment(blog.blogDate).format('Do MMM YYYY')}
                    </Typography>
                    <Box sx={{ height: '75px' }}>
                      <Typography
                        gutterBottom
                        variant="body1"
                        component="div"
                        className="text-ellipsis--3"
                        sx={{
                          fontWeight: 'bold',
                        }}
                      >
                        {blog.title}
                      </Typography>
                    </Box>

                    <Box sx={{ height: '25px' }}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        component="div"
                        className="text-ellipsis--2"
                      >
                        {blog.metaDescription}
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardActions sx={{ justifyContent: 'space-between' }}>
                    <Box>
                      {blog.publishedBlog &&
                        blog.publishedBlog.version < blog.version && (
                          <Tooltip title="New Changes Available">
                            <IconButton>
                              <WarningRounded color="warning" />
                            </IconButton>
                          </Tooltip>
                        )}
                    </Box>
                    <Chip
                      label={
                        blog.publishedBlog
                          ? blog.publishedBlog.live
                            ? 'PUBLISHED'
                            : 'REMOVED'
                          : 'SAVED AS DARFT'
                      }
                      color={
                        blog.publishedBlog
                          ? blog.publishedBlog.live
                            ? 'success'
                            : 'error'
                          : 'info'
                      }
                    />
                  </CardActions>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      )}
      {data?.data?.length === 0 && (
        <Box
          sx={{
            height: '300px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="h3" color="#eee">
            Add New Blogs!
          </Typography>
        </Box>
      )}
      <Box sx={{ my: 2, display: 'flex', justifyContent: 'flex-end' }}>
        {data?.totalCount > 0 && (
          <Pagination
            count={Math.ceil(data.totalCount / PAGE_SIZE)}
            onChange={(a, b) => setPageNumber(b)}
          />
        )}
      </Box>
    </Container>
  )
}
