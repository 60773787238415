import { Box, CircularProgress, Typography } from '@mui/material'
import { secondaryColor } from '../constants'

export default function PageLoader({ message, dark }) {
  if (!dark) {
    return (
      <Box
        sx={{
          position: 'fixed',
          width: '100%',
          height: '92vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <CircularProgress sx={{ color: secondaryColor, my: 3 }} />
      </Box>
    )
  }
  return (
    <Box
      sx={{
        position: 'fixed',
        width: '100%',
        height: '92vh',
        background: 'rgb(0,0,0,0.4)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        zIndex: (theme) => theme.zIndex.drawer,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          background: 'white',
          borderRadius: '10px',
          boxShadow: `rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px !important`,
          padding: '20px',
          width: '250px',
        }}
      >
        <CircularProgress sx={{ color: secondaryColor, my: 3 }} />
        <Typography variant="caption" color="initial">
          {message || 'Loading...'}
        </Typography>
      </Box>
    </Box>
  )
}
