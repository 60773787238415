import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Box,
  Typography,
  Paper,
  Stack,
  IconButton,
  TextField,
} from '@mui/material'
import { useState, useEffect } from 'react'
import CropIcon from '@mui/icons-material/Crop'
import TuneIcon from '@mui/icons-material/Tune'
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark'
import ImageCropper from './ImageCropper'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import ImageAdjuster from './ImageAdjuster'
import ImageWatermarkAdder from './ImageWatermarkAdder'
import isMobile from 'is-mobile'
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation'
import ImageRotator from './ImageRotator'
import SimpleImage from './SimpleImage'

export default function ImageEditor({
  image,
  onSave,
  onCancel,
  open,
  imageIndex,
}) {
  const [editImage, setEditImage] = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  const [activeEditor, setActiveEditor] = useState(null)

  useEffect(() => {
    if (image) {
      resetImage()
    } else {
      setEditImage(null)
    }
  }, [image])

  const resetImage = () => {
    const reader = new FileReader()
    reader.onloadend = () => {
      const img = new Image()
      img.src = reader.result
      img.onload = () => {
        setEditImage(reader.result)
      }
    }
    reader.readAsDataURL(image)
  }

  const handleChange = (image) => {
    setEditImage(image)
    setIsEditing(false)
    setActiveEditor(null)
  }

  const resetEditor = () => {
    setIsEditing(false)
    setActiveEditor(null)
    resetImage()
  }

  const resetToolKit = () => {
    setIsEditing(false)
    setActiveEditor(null)
  }

  const fullScreen = isMobile()

  const handleOptionClick = (action) => {
    setActiveEditor(action)
    setIsEditing(true)
  }

  const editorOptions = [
    {
      action: 'rotate',
      name: 'Rotate',
      icon: <ScreenRotationIcon />,
    },
    {
      action: 'crop',
      name: 'Crop',
      icon: <CropIcon />,
    },
    {
      action: 'adjust',
      name: 'Adjust',
      icon: <TuneIcon />,
    },
    {
      action: 'watermark',
      name: 'Watermark',
      icon: <BrandingWatermarkIcon />,
    },
  ]

  return (
    <Dialog
      fullScreen={isMobile()}
      open={open}
      onClose={() => {}}
      fullWidth={true}
      maxWidth={'xl'}
    >
      <DialogTitle>Image Editor</DialogTitle>
      <DialogContent
        sx={{
          minHeight: '70vh',
          padding: isMobile() ? '5px' : '20px',
          overflowX: 'hidden',
        }}
      >
        {editImage && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile() ? 'column' : 'row',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: isMobile() ? 'row' : 'column',
                width: isMobile() ? '100%' : '10%',
                paddingRight: '10px',
                justifyContent: isMobile() ? 'space-around' : 'flex-start',
              }}
            >
              <Stack
                direction={fullScreen ? 'row' : 'column'}
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                {editorOptions.map((option, index) => (
                  <IconButton
                    key={index}
                    onClick={() => handleOptionClick(option.action)}
                    color={
                      isEditing && activeEditor === option.action
                        ? 'primary'
                        : 'default'
                    }
                    disabled={isEditing && activeEditor !== option.action}
                    sx={{
                      width: '65px',
                      height: '65px',
                      flexDirection: 'column',
                      '& .MuiSvgIcon-root': { mb: 0.5 },
                    }}
                  >
                    {option.icon}
                    <Typography variant="caption">{option.name}</Typography>
                  </IconButton>
                ))}
              </Stack>
            </Box>
            {(() => {
              switch (activeEditor) {
                case 'crop':
                  return (
                    <ImageCropper
                      editImage={editImage}
                      onChange={handleChange}
                      onCancel={() => resetToolKit()}
                    />
                  )
                case 'adjust':
                  return (
                    <ImageAdjuster
                      editImage={editImage}
                      onChange={handleChange}
                      onCancel={() => resetToolKit()}
                    />
                  )
                case 'watermark':
                  return (
                    <ImageWatermarkAdder
                      editImage={editImage}
                      onChange={handleChange}
                      onCancel={() => resetToolKit()}
                    />
                  )
                case 'rotate':
                  return (
                    <ImageRotator
                      editImage={editImage}
                      onChange={handleChange}
                      onCancel={() => resetToolKit()}
                    />
                  )
                default:
                  return <SimpleImage image={editImage} />
              }
            })()}
            {/* {!isEditing && (
              <Box
                sx={{
                  width: isMobile() ? '100%' : '90%',
                }}
              >
                <Box
                  sx={{
                    width: isMobile() ? '100%' : '50%',
                  }}
                >
                  <img
                    src={editImage}
                    style={{
                      width: '100%',
                      height: 'auto',
                    }}
                    alt="edit"
                  />
                </Box>
              </Box>
            )} */}
          </Box>
        )}
      </DialogContent>
      {isMobile() && !isEditing && (
        <DialogActions>
          <Button onClick={() => resetEditor()} color="warning">
            Reset
          </Button>
          <Button variant="outlined" color="error" onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => onSave(editImage, imageIndex)}
          >
            Upload Image
          </Button>
        </DialogActions>
      )}
      {!isMobile() && (
        <DialogActions>
          <Button
            onClick={() => resetEditor()}
            color="warning"
            disabled={isEditing}
          >
            Reset
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={() => onCancel()}
            disabled={isEditing}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => onSave(editImage, imageIndex)}
            disabled={isEditing}
          >
            Upload Image
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}
