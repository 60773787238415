import React, { useState } from 'react'
import { Snackbar } from '@mui/material'
import MuiAlert from '@mui/material/Alert'

function useSnackbar() {
  const [open, setOpen] = useState()
  const [type, setType] = useState(null)
  const [message, setMessage] = useState()

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const snackbarElement = (
    <>
      {type === 'success' && (
        <Snackbar
          open={open}
          autoHideDuration={4000}
          message={message}
          onClose={handleClose}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={handleClose}
            severity="success"
            sx={{ width: '100%' }}
          >
            {message}
          </MuiAlert>
        </Snackbar>
      )}
      {type === 'error' && (
        <Snackbar
          open={open}
          autoHideDuration={4000}
          message={message}
          onClose={handleClose}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={handleClose}
            severity="error"
            sx={{ width: '100%' }}
          >
            {message}
          </MuiAlert>
        </Snackbar>
      )}
      {!type && (
        <Snackbar
          open={open}
          autoHideDuration={4000}
          message={message}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  )

  const notify = (message) => {
    setType(null)
    setMessage(message)
    setOpen(true)
  }

  const notifySuccess = (message) => {
    setMessage(message)
    setType('success')
    setOpen(true)
  }

  const notifyError = (message) => {
    setType('error')
    setMessage(message)
    setOpen(true)
  }

  return {
    notify,
    notifySuccess,
    notifyError,
    snackbarElement,
  }
}

export default useSnackbar
