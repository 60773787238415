import React, { useState, useRef } from 'react'
import {
  Grid,
  Box,
  Typography,
  CircularProgress,
  IconButton,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material'
import { Add, Delete, Edit } from '@mui/icons-material'
import { grey } from '@mui/material/colors'
import {
  generateUUID,
  uploadImage,
  uploadTempImage,
} from '../Services/Services'
import ImageEditor from './ImageEditor'
import useNotify from '../Hooks/useNotify'

export default function GalleryGrid({ images, onChange }) {
  const { notifySuccess, notifyError } = useNotify()
  const [uploadProgress, setUploadProgress] = useState(false)
  const [uploadAtIndexProgress, setUploadAtIndexProgress] = useState(false)
  const [uploadAtIndex, setUploadAtIndex] = useState(null)
  const imageUploadRef = useRef(null)
  const imageUploadIndexdRef = useRef(null)
  const [imageEditorConfig, setImageEditorConfig] = useState({
    open: false,
    image: null,
    onSave: null,
    onCancel: null,
  })

  const [alertConf, setAlertConf] = useState({})

  const triggerfileUpload = () => {
    imageUploadRef.current.click()
  }
  function dataURLtoBlob(dataurl) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], { type: mime })
  }
  function blobToFile(blob, fileName) {
    return new File([blob], fileName, { type: blob.type })
  }

  function resetEditor() {
    setImageEditorConfig({
      open: false,
      image: null,
      onSave: null,
      onCancel: null,
    })
  }

  async function uploadImageAfterEditing(image, imageIndex) {
    resetEditor()
    const blob = dataURLtoBlob(image)
    const file = blobToFile(blob, 'edited-file.jpg')
    await UploadToServer(file, imageIndex)
  }

  const UploadToServer = async (file, imageIndex) => {
    if (imageIndex !== undefined) {
      setUploadAtIndexProgress(true)
    } else {
      setUploadProgress(true)
    }
    try {
      const response = await uploadTempImage(file)
      if (imageIndex !== undefined) {
        const temp = [...images]
        temp[uploadAtIndex].url = response.url
        temp[uploadAtIndex].thumbnail = response.thumbnailUrl
        onChange(temp)
      } else {
        const img = {
          ...response,
          alt: '',
          id: generateUUID(),
        }
        onChange((prev) => [...prev, img])
      }
    } catch (e) {
      console.error(e) // Handle the error appropriately
      notifyError('Error occured while uploading image')
    } finally {
      if (imageIndex !== undefined) {
        setUploadAtIndexProgress(false)
      } else {
        setUploadProgress(false)
      }
    }
  }

  function openImageEditor(image, imageIndex) {
    setImageEditorConfig({
      open: true,
      image: image,
      imageIndex: imageIndex,
      onSave: uploadImageAfterEditing,
      onCancel: resetEditor,
    })
  }

  const newImageSelected = (event) => {
    const file = event.target.files[0]
    setAlertConf({
      open: true,
      title: 'Confirm',
      message: 'Do you want to edit the image before uploading?',
      onSubmit: () => {
        openImageEditor(file)
        imageUploadRef.current.value = ''
      },
      onCancel: () => {
        UploadToServer(file)
        imageUploadRef.current.value = ''
      },
    })
  }

  const newImageSelectedAtIndex = async (event) => {
    const file = event.target.files[0]
    setAlertConf({
      open: true,
      title: 'Confirm',
      message: 'Do you want to edit the image before uploading?',
      onSubmit: () => {
        openImageEditor(file, uploadAtIndex)
        imageUploadIndexdRef.current.value = ''
      },
      onCancel: () => {
        UploadToServer(file, uploadAtIndex)
        imageUploadIndexdRef.current.value = ''
      },
    })
  }

  return (
    <>
      <Grid container spacing={3}>
        {images.map((image, index) => (
          <Grid item xs={12} md={6} lg={4} key={image.id}>
            <Box
              sx={{
                position: 'relative',
                height: '250px',
                width: '100%',
                background: 'black',
              }}
            >
              <Box
                sx={{
                  zIndex: 2000,
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                  background: 'rgba(0,0,0,0.5)',
                  backdropFilter: 'blur(5px)',
                  display:
                    uploadAtIndexProgress && uploadAtIndex === index
                      ? 'flex'
                      : 'none',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <div>{<CircularProgress color="warning" />}</div>
                <Typography variant="body1" color="white">
                  Uploading....
                </Typography>
              </Box>
              <img
                style={{
                  height: '100%',
                  width: '100%',
                  objectFit: 'contain',
                }}
                src={image.url}
                alt=""
              />
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  width: '100%',
                  background: 'rgb(0,0,0,0.4)',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    width: '100%',
                  }}
                >
                  <IconButton
                    onClick={() => {
                      setUploadAtIndex(index)
                      imageUploadIndexdRef.current.click()
                    }}
                  >
                    <Edit sx={{ color: 'white' }} />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      const temp = [...images]
                      temp.splice(index, 1)
                      onChange(temp)
                    }}
                  >
                    <Delete sx={{ color: 'white' }} />
                  </IconButton>
                </Box>
              </Box>
            </Box>
            <Box sx={{ mt: 1 }}>
              <TextField
                fullWidth
                id="standard-basic"
                label={`Image ${index + 1} Alt text`}
                variant="standard"
                value={image.alt}
                onChange={(e) =>
                  onChange((prev) => {
                    const temp = [...prev]
                    temp[index].alt = e.target.value
                    return temp
                  })
                }
              />
            </Box>
          </Grid>
        ))}
        <Grid item xs={12} md={6} lg={4}>
          {!uploadProgress && (
            <Box
              sx={{
                height: '250px',
                width: '100%',
                backgroundColor: grey[300],
                borderRadius: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                overflow: 'hidden',
                position: 'relative',
              }}
            >
              <>
                <IconButton onClick={() => triggerfileUpload()}>
                  <Add sx={{ fontSize: '50px' }} />
                </IconButton>
                <Typography variant="body1" color="initial">
                  {'Upload Image'}
                </Typography>
              </>
            </Box>
          )}
          {uploadProgress && (
            <Box
              sx={{
                height: '250px',
                width: '100%',
                backgroundColor: grey[300],
                borderRadius: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                overflow: 'hidden',
                position: 'relative',
              }}
            >
              <CircularProgress color="warning" />
              <Typography variant="body1" color="initial">
                uploading...
              </Typography>
            </Box>
          )}
        </Grid>

        <input
          ref={imageUploadRef}
          style={{ display: 'none' }}
          type="file"
          accept="image/*"
          onChange={(e) => newImageSelected(e)}
        />
        <input
          ref={imageUploadIndexdRef}
          style={{ display: 'none' }}
          type="file"
          accept="image/*"
          onChange={(e) => newImageSelectedAtIndex(e)}
        />
      </Grid>
      {imageEditorConfig.open && (
        <ImageEditor
          imageIndex={imageEditorConfig.imageIndex}
          image={imageEditorConfig.image}
          onSave={imageEditorConfig.onSave}
          onCancel={imageEditorConfig.onCancel}
          open={imageEditorConfig.open}
        />
      )}

      {alertConf.open && (
        <Dialog
          open={alertConf.open}
          onClose={() => {}}
          fullWidth={true}
          maxWidth={'sm'}
        >
          <DialogTitle>{alertConf.title}</DialogTitle>
          <DialogContent>
            <Typography variant="body2" color="initial">
              {alertConf.message}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                alertConf.onCancel()
                setAlertConf({})
              }}
            >
              Continue upload
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                alertConf.onSubmit()
                setAlertConf({})
              }}
            >
              Open Editor
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}
