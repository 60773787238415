import axios from 'axios'
import moment from 'moment/moment'
import mobile from 'is-mobile'
import { serverUrl } from '../constants'

function getToken() {
  let localToken = localStorage.getItem('token')
  return localToken ? `Bearer ${localToken}` : null
}

export function addBlog(blog) {
  return axios({
    method: 'post',
    url: `${serverUrl}/blogs`,
    data: blog,
    headers: {
      Authorization: getToken(),
    },
  }).then(function (response) {
    return response.data
  })
}

export function updateBlog(id, changes) {
  return axios({
    method: 'put',
    url: `${serverUrl}/blogs/${id}`,
    data: changes,
    headers: {
      Authorization: getToken(),
    },
  }).then(function (response) {
    return response.data
  })
}

export function uploadImage(file) {
  const data = new FormData()
  data.append('file', file)
  return axios({
    method: 'post',
    url: `${serverUrl}/images`,
    data: data,
    headers: {
      Authorization: getToken(),
    },
  }).then(function (response) {
    return response.data
  })
}

export function uploadTempImage(file) {
  const data = new FormData()
  data.append('file', file)
  return axios({
    method: 'post',
    url: `${serverUrl}/temp-images`,
    data: data,
    headers: {
      Authorization: getToken(),
    },
  }).then(function (response) {
    return response.data
  })
}

export function getBlogs(page = 1, pageSize = 10, searchString) {
  return axios({
    method: 'get',
    url: `${serverUrl}/blogs?page=${page}&pageSize=${pageSize}${
      searchString ? `&search=${searchString}` : ''
    }`,
    headers: {
      Authorization: getToken(),
    },
  }).then(function (response) {
    return response.data
  })
}

export function getBlogDetails(id) {
  return axios({
    method: 'get',
    url: `${serverUrl}/blogs/${id}`,
    headers: {
      Authorization: getToken(),
    },
  }).then(function (response) {
    return response.data
  })
}

export function publishBlog(id) {
  return axios({
    method: 'post',
    url: `${serverUrl}/blogs/${id}/publish`,
    headers: {
      Authorization: getToken(),
    },
  }).then(function (response) {
    return response.data
  })
}

export function publishBlogChanges(id) {
  return axios({
    method: 'put',
    url: `${serverUrl}/blogs/${id}/publish`,
    headers: {
      Authorization: getToken(),
    },
  }).then(function (response) {
    return response.data
  })
}

export function removeBlog(id) {
  return axios({
    method: 'delete',
    url: `${serverUrl}/blogs/${id}/publish`,
    headers: {
      Authorization: getToken(),
    },
  }).then(function (response) {
    return response.data
  })
}

export function republishBlog(id) {
  return axios({
    method: 'put',
    url: `${serverUrl}/blogs/${id}/re-publish`,
    headers: {
      Authorization: getToken(),
    },
  }).then(function (response) {
    return response.data
  })
}

export function republishBlogLatest(id) {
  return axios({
    method: 'put',
    url: `${serverUrl}/blogs/${id}/re-publish-new`,
    headers: {
      Authorization: getToken(),
    },
  }).then(function (response) {
    return response.data
  })
}

export function addGalleryItem(galleryItem) {
  return axios({
    method: 'post',
    url: `${serverUrl}/albums`,
    data: galleryItem,
    headers: {
      Authorization: getToken(),
    },
  }).then(function (response) {
    return response.data
  })
}

export function updateGalleryItem(id, changes) {
  return axios({
    method: 'put',
    url: `${serverUrl}/albums/${id}`,
    data: changes,
    headers: {
      Authorization: getToken(),
    },
  }).then(function (response) {
    return response.data
  })
}

export function getGalleryItems(page = 1, pageSize = 10) {
  return axios({
    method: 'get',
    url: `${serverUrl}/albums?page=${page}&pageSize=${pageSize}`,
    headers: {
      Authorization: getToken(),
    },
  }).then(function (response) {
    return response.data
  })
}

export function getGalleryItemDetails(id) {
  return axios({
    method: 'get',
    url: `${serverUrl}/albums/${id}`,
    headers: {
      Authorization: getToken(),
    },
  }).then(function (response) {
    return response.data
  })
}

export function publishGalleryItem(id) {
  return axios({
    method: 'post',
    url: `${serverUrl}/albums/${id}/publish`,
    headers: {
      Authorization: getToken(),
    },
  }).then(function (response) {
    return response.data
  })
}

export function publishGalleryItemChanges(id) {
  return axios({
    method: 'put',
    url: `${serverUrl}/albums/${id}/publish`,
    headers: {
      Authorization: getToken(),
    },
  }).then(function (response) {
    return response.data
  })
}

export function removePublishedAlbum(id) {
  return axios({
    method: 'delete',
    url: `${serverUrl}/albums/${id}/publish`,
    headers: {
      Authorization: getToken(),
    },
  }).then(function (response) {
    return response.data
  })
}

export function republishAlbum(id) {
  return axios({
    method: 'put',
    url: `${serverUrl}/albums/${id}/re-publish`,
    headers: {
      Authorization: getToken(),
    },
  }).then(function (response) {
    return response.data
  })
}

export function isMobile() {
  return mobile()
}

export function equalDate(d1, d2) {
  return new Date(d1).getTime() === new Date(d2).getTime()
}

export function isDate(str) {
  return moment(str, moment.ISO_8601, true).isValid()
}

export function getObjectDiff(old, latest) {
  const result = {}
  for (let key in latest) {
    if (old[key] !== undefined) {
      if (isDate(old[key])) {
        if (!equalDate(latest[key], old[key])) {
          result[key] = latest[key]
        }
      } else if (latest[key] !== old[key]) {
        result[key] = latest[key]
      }
    } else {
      result[key] = latest[key]
    }
  }
  return Object.keys(result).length ? result : null
}

export function getArrayDiff(old, latest, key) {
  let added = []
  let removed = []
  let updated = []
  let oldKeys = old.reduce((acc, i) => {
    acc[i[key]] = i
    return acc
  }, {})

  let latestKeys = latest.reduce((acc, i) => {
    acc[i[key]] = i
    return acc
  }, {})

  old.forEach((i) => {
    if (latestKeys[i[key]]) {
      let diff = getObjectDiff(i, latestKeys[i[key]])
      if (diff) {
        updated.push(latestKeys[i[key]])
      }
    } else {
      removed.push(i)
    }
  })

  latest.forEach((i) => {
    if (!oldKeys[i[key]]) {
      added.push(i)
    }
  })

  return { added, removed, updated }
}

export function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    // eslint-disable-next-line
    var r = (Math.random() * 16) | 0,
      // eslint-disable-next-line
      v = c == 'x' ? r : (r & 0x3) | 0x8
    // eslint-disable-next-line
    return v.toString(16)
  })
}
