import { Container as MuiContainer } from '@mui/material'
import { isMobile } from '../Services/Services'

export default function Container({ children }) {
  if (isMobile()) {
    return <>{children}</>
  }

  return <MuiContainer>{children}</MuiContainer>
}
