import AdminAppBar from '../AdminAppBar/AdminAppBar'
import BlogsListPage from '../BlogsListPage/BlogsListPage'
import EditBlog from '../EditBlog/EditBlog'
import NewBlog from '../NewBlog/NewBlog'
import BlogDetails from '../BlogDetails/BlogDetails'
import { Box, Snackbar } from '@mui/material'
import { Routes, Route } from 'react-router-dom'
import { useEffect, useContext, useRef, useState, useCallback } from 'react'
import useSearchQuery from '../Common/useSearchQuery'
import { Context } from '../Hooks/UserContext'
import { useNavigate } from 'react-router-dom'
import { serverUrl } from '../constants'
import PageLoader from '../Common/PageLoader'
import axios from 'axios'
import Login from '../Login/Login'
import useNotify from '../Hooks/useNotify'
import GalleryList from '../Gallery/GalleryList'
import NewGalleryItem from '../Gallery/NewGalleryItem'
import GalleryDetails from '../Gallery/GalleryDetails'
import EditGalleryItem from '../Gallery/EditGalleryItem'

export default function AdminMainWindow() {
  const userContext = useContext(Context)
  const { notify } = useNotify()

  const query = useSearchQuery()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const dataFetchedRef = useRef(false)

  const proceedFurther = useCallback(() => {
    const lastLocation = localStorage.getItem('lastLocation')
    if (lastLocation) {
      localStorage.removeItem('lastLocation')
      navigate(lastLocation)
    }
  }, [navigate])

  const verifyUser = useCallback(
    async (token) => {
      try {
        const data = await axios
          .post(`${serverUrl}/verify-token`, {
            token,
          })
          .then((response) => response.data)
        return data
      } catch (e) {
        if (e?.response?.data?.message) {
          notify(e?.response?.data?.message)
        }
        return null
      }
    },
    [notify]
  )

  const validate = useCallback(async () => {
    setLoading(true)
    let existingToken = localStorage.getItem('token')
    const newToken = query.get('token')
    if (newToken) {
      localStorage.removeItem('token')
      let result = await verifyUser(newToken)
      if (result?.valid) {
        localStorage.setItem('token', newToken)
        userContext.setCurrUser(result.user)
        proceedFurther()
      } else {
        notify('Unauthorized User.')
      }
    } else if (existingToken) {
      let result = await verifyUser(existingToken)
      if (result?.valid) {
        userContext.setCurrUser(result.user)
        proceedFurther()
      } else {
        notify('Unauthorized User.')
      }
    }
    setLoading(false)
  }, [proceedFurther, verifyUser, query, userContext, notify])

  useEffect(() => {
    if (!dataFetchedRef.current) {
      if (!query.get('unauthorizedLogin')) {
        console.log('Verifying Token......')
        validate()
      } else {
        if (query.get('unauthorizedLogin')) {
          notify('Unauthorized User.')
        }
      }
      dataFetchedRef.current = true
    }
  }, [validate, query, notify])

  if (loading) {
    return <PageLoader />
  }

  if (!userContext.currUser || query.get('unauthorizedLogin')) {
    return <Login />
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <AdminAppBar />
      <Routes>
        <Route path="/" element={getMainView(<BlogsListPage />)} />
        <Route
          path="/blogs/:id/edit/:type"
          element={getMainView(<EditBlog />)}
        />
        <Route path="/blogs/:id" element={getMainView(<BlogDetails />)} />
        <Route path="/blogs/new" element={getMainView(<NewBlog />)} />
        <Route path="/gallery" element={getMainView(<GalleryList />)} />
        <Route path="/gallery/new" element={getMainView(<NewGalleryItem />)} />
        <Route path="/gallery/:id" element={getMainView(<GalleryDetails />)} />
        <Route
          path="/gallery/:id/edit"
          element={getMainView(<EditGalleryItem />)}
        />
      </Routes>
    </Box>
  )
}

function getMainView(component) {
  return (
    <Box
      sx={{
        pt: '81px',
        width: '100%',
      }}
    >
      <div style={{ width: '100%' }}>{component}</div>
    </Box>
  )
}
