import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  CardActions,
  IconButton,
  Fab,
  Pagination,
  Chip,
  Button,
  Tooltip,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import PageSearch from '../Common/PageSearch'
import { useQuery } from 'react-query'
import {
  getBlogs,
  getGalleryItem,
  getGalleryItems,
  isMobile,
} from '../Services/Services'
import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import moment from 'moment/moment'
import { CalendarMonthOutlined, WarningRounded } from '@mui/icons-material'
import { primaryColor, secondaryColor } from '../constants'
import PageLoader from '../Common/PageLoader'
import useNotify from '../Hooks/useNotify'
import Container from '../Common/Container'
import CollectionsIcon from '@mui/icons-material/Collections'

export default function GalleryList() {
  const navigate = useNavigate()
  const { notifyError } = useNotify()

  const [pageNumber, setPageNumber] = useState(1)
  const PAGE_SIZE = 15

  const { isLoading, error, data } = useQuery(
    ['gallery-items', pageNumber],
    () => getGalleryItems(pageNumber, PAGE_SIZE),
    {
      keepPreviousData: true,
    }
  )

  useEffect(() => {
    if (error) {
      notifyError('Some Error Occured!')
    }
  }, [error, notifyError])

  if (isLoading) {
    return <PageLoader />
  }

  const goToNewGalleryItem = () => {
    navigate('/gallery/new')
  }

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          my: 2,
        }}
      >
        <Typography variant="h5" color="initial">
          Albums
        </Typography>

        <Button
          onClick={goToNewGalleryItem}
          variant="contained"
          startIcon={<AddIcon />}
          sx={{
            background: secondaryColor,
            color: 'black',
            '&:hover': {
              background: '#a3dcfc',
            },
          }}
        >
          Add
        </Button>
      </Box>
      {data?.data?.length > 0 && (
        <Grid container spacing={isMobile() || true ? 0 : 4}>
          {data?.data?.map((item) => (
            <Grid item xs={12} md={6} lg={3} key={item._id}>
              <Link
                style={{ color: 'black', textDecoration: 'none' }}
                to={`/gallery/${item._id}`}
              >
                <Box
                  sx={{
                    background: '#fff',
                    boxShadow:
                      'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px !important',
                    borderRadius: '10px',
                    overflow: 'hidden',
                    m: 1,
                    p: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                      height: '250px',
                      // background: 'black',
                      position: 'relative',
                      overflow: 'hidden',
                    }}
                  >
                    <img
                      style={{
                        height: '100%',
                        width: '100%',
                        objectFit: 'contain',
                      }}
                      src={item.images[0].url || '../default.png'}
                      alt="img"
                    />
                    {item.images.length > 1 && (
                      <Tooltip title={`${item.images.length} images`}>
                        <Box
                          sx={{
                            position: 'absolute',
                            top: '7px',
                            right: '7px',
                            height: '30px',
                            width: '30px',
                            borderRadius: '50%',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <CollectionsIcon
                            sx={{ color: 'white', fontSize: '15px' }}
                          />
                        </Box>
                      </Tooltip>
                    )}
                  </Box>
                  <Box
                    sx={{
                      pt: 1,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <CalendarMonthOutlined sx={{ mr: 1 }} />
                      <Typography variant="caption">
                        {moment(item.createdAt).format('DD MMM YYYY')}
                      </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box>
                        {item.published &&
                          item.published.version < item.version && (
                            <Tooltip title="New Changes Available">
                              <IconButton>
                                <WarningRounded color="warning" />
                              </IconButton>
                            </Tooltip>
                          )}
                      </Box>
                      <Chip
                        label={
                          item.published
                            ? item.published.live
                              ? 'PUBLISHED'
                              : 'REMOVED'
                            : 'DARFT'
                        }
                        color={
                          item.published
                            ? item.published.live
                              ? 'success'
                              : 'error'
                            : 'info'
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              </Link>
            </Grid>
          ))}
        </Grid>
      )}
      {data?.data?.length === 0 && (
        <Card>
          <CardContent>
            <Box
              sx={{
                height: '300px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography variant="h3" sx={{ color: primaryColor }}>
                Add New Album!
              </Typography>
            </Box>
          </CardContent>
        </Card>
      )}
      <Box sx={{ my: 2, display: 'flex', justifyContent: 'flex-end' }}>
        {data?.totalCount > 0 && (
          <Pagination
            count={Math.ceil(data.totalCount / PAGE_SIZE)}
            onChange={(a, b) => setPageNumber(b)}
          />
        )}
      </Box>
    </Container>
  )
}
