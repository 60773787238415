import { Box, FormLabel } from '@mui/material'
import Required from './Required'

export default function FormItem({ label, required, children }) {
  return (
    <Box sx={{ my: 2 }}>
      <Box sx={{ mb: 1 }}>
        <FormLabel>
          {label} {required && <Required />}
        </FormLabel>
      </Box>
      {/* <Typography variant="body2" color="initial" component={'div'}>
        
      </Typography> */}
      {children}
    </Box>
  )
}
