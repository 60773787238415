import { Typography } from '@mui/material'

export default function Required() {
  return (
    <Typography
      variant="body1"
      color="initial"
      component={'span'}
      sx={{ color: 'red' }}
    >
      *
    </Typography>
  )
}
