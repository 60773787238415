import ReactCrop, { Crop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { useState, useRef } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Box,
  Typography,
  Slider,
  TextField,
  Stack,
  IconButton,
} from '@mui/material'
import { useEffect } from 'react'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import Draggable from 'react-draggable'
import isMobile from 'is-mobile'

export default function ImageWatermarkAdder({ editImage, onChange, onCancel }) {
  const initialSize = isMobile() ? 80 : 150
  const imageRef = useRef(null)
  const watermarkRef = useRef(null)
  const [size, setSize] = useState(initialSize) // Assuming default dimensions
  const [watermarkPosition, setWatermarkPosition] = useState({ x: 0, y: 0 })
  const [watermarkDimensions, setWatermarkDimensions] = useState({
    width: 0,
    height: 0,
  }) // Assuming default dimensions

  const cropImage = () => {
    const croppedDataURL = getCroppedImage()
    onChange(croppedDataURL)
  }

  const onWatermarkDragStop = (e, data) => {
    setWatermarkPosition({ x: data.x, y: data.y })
  }

  function getCroppedImage() {
    const image = imageRef.current
    const watermark = watermarkRef.current

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    const canvas = document.createElement('canvas')
    canvas.width = image.naturalWidth
    canvas.height = image.naturalHeight
    const ctx = canvas.getContext('2d')
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height)

    const watermarkScaledX = watermarkPosition.x * scaleX
    const watermarkScaledY = watermarkPosition.y * scaleY
    const watermarkScaledWidth = watermarkDimensions.width * scaleX
    const watermarkScaledHeight = watermarkDimensions.height * scaleY

    ctx.drawImage(
      watermark,
      watermarkScaledX,
      watermarkScaledY,
      watermarkScaledWidth,
      watermarkScaledHeight
    )
    // ctx.drawImage(watermark, 0, 0, 100, 100)

    return canvas.toDataURL('image/jpeg')
  }

  const download = () => {
    const croppedDataURL = getCroppedImage()
    const downloadLink = document.createElement('a')
    downloadLink.href = croppedDataURL
    downloadLink.download = 'cropped-image.jpg'
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  const handleSliderChange = (value) => {
    if (value + watermarkPosition.x > imageRef.current.width) {
      setWatermarkPosition((prev) => {
        return {
          x: imageRef.current.width - value,
          y: prev.y,
        }
      })
    }
    setSize(value)
  }

  useEffect(() => {
    if (size !== undefined) {
      setWatermarkDimensions({ width: size, height: size })
    }
  }, [size])

  return (
    <Box
      sx={{
        width: isMobile() ? '100%' : '90%',
        display: 'flex',
        flexDirection: isMobile() ? 'column' : 'row',
      }}
    >
      <Box
        sx={{
          width: isMobile() ? '100%' : '50%',
          position: 'relative',
        }}
        className="draggable-parent"
      >
        <img
          ref={imageRef}
          src={editImage}
          style={{
            width: '100%',
            height: 'auto',
          }}
          alt="edit"
        />
        <Draggable bounds="parent" onStop={onWatermarkDragStop}>
          <div
            className="draggable-container"
            style={{
              width: watermarkDimensions.width,
              height: watermarkDimensions.height,
            }}
          >
            <img
              src="/wm.png"
              style={{ width: '100%', height: '100%' }}
              ref={watermarkRef}
              onMouseDown={() => false}
              draggable={false}
            />
          </div>
        </Draggable>
      </Box>
      <Box
        sx={{
          padding: '20px 0px',
          width: isMobile() ? '100%' : '50%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        className="toolkit"
      >
        <Box
          sx={{
            marginBottom: isMobile() ? '10px' : '30px',
            width: isMobile() ? '100%' : '50%',
          }}
        >
          <Typography variant="subtitle2" gutterBottom>
            Size {size}
          </Typography>
          <Stack spacing={2} direction="row" alignItems="center">
            <Slider
              min={0}
              max={200}
              step={1}
              value={typeof size === 'number' ? size : 0}
              onChange={(e) => handleSliderChange(e.target.value)}
              aria-labelledby="input-slider"
            />
            <IconButton onClick={() => setSize(100)}>
              <RestartAltIcon />
            </IconButton>
          </Stack>
        </Box>
        <Button
          sx={{
            marginBottom: isMobile() ? '10px' : '30px',
            width: isMobile() ? '100%' : '50%',
          }}
          variant="contained"
          onClick={() => cropImage()}
        >
          Apply Water mark
        </Button>
        {/* <Button
          sx={{ marginBottom: isMobile() ? '10px' : '30px', width: isMobile() ? '100%' : '50%' }}
          variant="outlined"
          onClick={() => download()}
        >
          Download Image
        </Button> */}
        <Button
          sx={{
            marginBottom: isMobile() ? '10px' : '30px',
            width: isMobile() ? '100%' : '50%',
            color: 'gray',
          }}
          onClick={() => onCancel()}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  )
}
