import { Grid, Typography, Card, CardContent, Box, Button } from '@mui/material'
import Container from '../Common/Container'
import { useState } from 'react'
import RichEditor from '../Common/RichEditor'
import { addGalleryItem, isMobile } from '../Services/Services'
import GalleryGrid from './GalleryGrid'
import { useNavigate } from 'react-router-dom'
import useNotify from '../Hooks/useNotify'
import PageLoader from '../Common/PageLoader'

export default function NewGalleryItem() {
  const navigate = useNavigate()
  const { notifySuccess, notifyError } = useNotify()
  const [caption, setCaption] = useState('')
  const [images, setImages] = useState([])
  const [reqInProgress, setReqInProgress] = useState(false)

  const save = async () => {
    if (images.length === 0) {
      notifyError(`Please add atleast one image`)
      return
    }

    if (caption === '') {
      notifyError(`Please add caption`)
      return
    }

    if (images.filter((i) => i.alt?.trim() === '').length > 0) {
      notifyError(`Please add alt text for all images`)
      return
    }

    setReqInProgress(true)
    try {
      await addGalleryItem({ caption, images })
      notifySuccess('Item Added Successfully')
      setTimeout(() => {
        navigate('/gallery')
      }, 500)
    } catch (e) {
      if (e?.response?.data?.message) {
        notifyError(`${e?.response?.data?.message}`)
      } else {
        notifyError('Some Error Occured!')
      }
    } finally {
      setReqInProgress(false)
    }
  }

  const cancel = () => {
    navigate('/gallery')
  }

  return (
    <>
      {reqInProgress && (
        <PageLoader dark={true} message={'Creating new album ...'} />
      )}
      <Container>
        <Typography variant="h5" color="initial" sx={{ mb: 1 }}>
          New Album
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8}>
            <Grid container spacing={2}>
              {/* <Grid item xs={12} sm={12} md={12}>
                <Card>
                  <CardContent>
                    <TagEditor suggestions={['Apple', 'Banana', 'Cherry']} />
                  </CardContent>
                </Card>
              </Grid> */}
              <Grid item xs={12} sm={12} md={12}>
                <Card>
                  <CardContent>
                    <GalleryGrid images={images} onChange={setImages} />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box sx={!isMobile() ? { position: 'sticky', top: '75px' } : {}}>
              <RichEditor
                onChange={(e) => setCaption(e)}
                value={caption}
                height={window.innerHeight - 250}
                simpleToolbar={true}
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  my: 2,
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'grey.500',
                    mr: 2,
                    '&:hover': {
                      backgroundColor: 'grey.700',
                    },
                  }}
                  onClick={cancel}
                >
                  cancel
                </Button>
                <Button variant="contained" color="primary" onClick={save}>
                  Save
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
