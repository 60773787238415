import {
  Box,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  Drawer,
  List,
  ListItem,
  IconButton,
  ListItemButton,
  ListItemText,
  Button,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { primaryColor, serverUrl } from '../constants'
import { Inbox } from '@mui/icons-material'
import { useLocation, useNavigate } from 'react-router-dom'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import PeopleIcon from '@mui/icons-material/People'
import { useState, useContext } from 'react'
import { isMobile } from '../Services/Services'
import WebIcon from '@mui/icons-material/Web'
import CollectionsIcon from '@mui/icons-material/Collections'
import QuizIcon from '@mui/icons-material/Quiz'
import MenuIcon from '@mui/icons-material/Menu'
import { Context } from '../Hooks/UserContext'

export default function AdminAppBar({ children }) {
  const userContext = useContext(Context)

  const drawerWidth = '270px'
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const logout = () => {
    localStorage.removeItem('token')
    userContext.setCurrUser(null)
    navigate('/')
  }
  return (
    <>
      {/* <AppBar
        position="fixed"
        sx={{
          background: primaryColor,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>
            <Typography variant="h6" noWrap component="div">
              AMD Blog Admin
            </Typography>
          </Link>
        </Toolbar>
      </AppBar> */}

      <AppBar
        position="fixed"
        sx={{
          background: primaryColor,
          transition: 'all .5s ease',
          zIndex: (theme) =>
            isMobile() || true ? theme.zIndex.drawer : theme.zIndex.drawer + 1,
        }}
      >
        <Box
          sx={{
            padding: '10px 24px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {(isMobile() || true) && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setOpen(true)}
              edge="start"
              sx={{ mr: 1 }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Box
            sx={{
              background: 'white',
              borderRadius: '50%',
              padding: '7px',
              width: '30px',
              height: '30px',
              cursor: 'pointer',
              boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
              // boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)"
            }}
          >
            <img
              onClick={() => {
                navigate('/')
              }}
              src="/logo.png"
              alt="AMD Dental Clinic"
              style={{
                width: '100%',
              }}
            />
          </Box>
          <Box
            sx={{
              width: '1px',
              height: '45px',
              borderRadius: '9999px',
              background: 'rgba(255, 255, 255, 0.1)',
              margin: isMobile() ? '0 10px' : '0 25px',
            }}
          ></Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexGrow: 1,
            }}
          >
            <Box sx={{ minWidth: '20px' }}>
              {!isMobile() && (
                <Typography
                  variant="h6"
                  sx={{
                    background: 'rgba(0, 0, 0, 0.2)',
                    padding: '5px 25px',
                    borderRadius: '4px',
                  }}
                >
                  Webite Admin
                </Typography>
              )}
            </Box>
            {userContext.currUser && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  sx={{
                    background: 'rgba(0, 0, 0, 0.2)',
                    color: 'white',
                  }}
                  onClick={logout}
                >
                  Logout
                </Button>
                {serverUrl.includes('localhost') && (
                  <div
                    style={{
                      margin: '5px',
                      width: '10px',
                      height: '10px',
                      background: '#eeff41',
                      borderRadius: '50%',
                    }}
                  ></div>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </AppBar>

      <Drawer
        variant={`${isMobile() || true ? 'temporary' : 'permanent'}`}
        PaperProps={{
          sx: {
            background: 'rgb(249,250,251)',
          },
        }}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        anchor="left"
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box
          sx={{
            marginTop: isMobile() || true ? 0 : '70px',
          }}
        />
        <List sx={{ width: '100%', maxWidth: 360 }} component="nav">
          <AMDDrawerItem
            onItemClick={() => setOpen(false)}
            url={'/'}
            title={'Blogs'}
            icon={<WebIcon sx={{ fontSize: '20px' }} />}
          />
          <AMDDrawerItem
            onItemClick={() => setOpen(false)}
            url={'/gallery'}
            title={'Gallery'}
            icon={<CollectionsIcon sx={{ fontSize: '20px' }} />}
          />
          <AMDDrawerItem
            onItemClick={() => setOpen(false)}
            url={'/faqs'}
            title={'FAQs'}
            icon={<QuizIcon sx={{ fontSize: '20px' }} />}
          />
        </List>
      </Drawer>
    </>
  )
}

function AMDDrawerItem({ url, title, icon, child, onItemClick }) {
  const location = useLocation()
  const navigate = useNavigate()
  const active = location.pathname === url
  return (
    <ListItemButton
      sx={{
        pl: child ? 4 : 2,
        '&.selected': {
          background: 'green',
        },
      }}
      selected={active}
      onClick={() => {
        onItemClick()
        navigate(url)
      }}
    >
      <Box
        sx={{
          pl: '10px',
          color: active ? primaryColor : 'black',
        }}
      >
        {icon}
      </Box>
      <ListItemText
        primary={title}
        primaryTypographyProps={{
          sx: {
            fontWeight: '600',
            fontSize: '14px',
            padding: '0 10px',
            color: active ? primaryColor : 'black',
          },
        }}
      />
      <ChevronRightIcon
        sx={{
          color: active ? primaryColor : 'black',
        }}
      />
    </ListItemButton>
  )
}
