import useSnackbar from './Hooks/useSnackbar'
import { createContext } from 'react'

export const NotifyContext = createContext()

function NotificationContext({ children }) {
  const { notify, snackbarElement, notifySuccess, notifyError } = useSnackbar()
  return (
    <NotifyContext.Provider value={{ notify, notifySuccess, notifyError }}>
      {children}
      {snackbarElement}
    </NotifyContext.Provider>
  )
}

export default NotificationContext
