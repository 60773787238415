import {
  Stepper,
  Step,
  StepLabel,
  Card,
  CardContent,
  Box,
  Fab,
  TextField,
  Typography,
  Button,
  CircularProgress,
  InputAdornment,
  IconButton,
  FormControl,
  OutlinedInput,
  Grid,
  Select,
  MenuItem,
  Alert,
  Snackbar,
} from '@mui/material'
import FormItem from '../Common/FormItem'
import RichEditor from '../Common/RichEditor'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import React, { useRef, useState } from 'react'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import slugify from 'slugify'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { addBlog, isMobile, uploadImage } from '../Services/Services'
import Loader from '../Common/Loader'
import { useNavigate } from 'react-router-dom'
import Container from '../Common/Container'
import HtmlViewer from '../Common/HtmlViewer'
import HtmlEditor from '../Common/HtmlEditor'

const slugifyConf = {
  replacement: '-',
  lower: true,
  strict: true,
  trim: true,
  remove: /[*+~.()'"!:@?]/g,
}

export default function NewBlog() {
  const navigate = useNavigate()
  const fileUploadRef = useRef(null)
  const [activeState, setActiveState] = useState(0)
  const [snackbarConf, setSnackbarConf] = useState({})

  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [image, setImage] = useState(null)
  const [imageUrl, setImageUrl] = useState(null)
  const [imageThumbnailUrl, setImageThumbnailUrl] = useState(null)
  const [date, setDate] = useState(null)
  const [tags, setTags] = useState('')
  const [slug, setSlug] = useState('')
  const [metaTitle, setMetaTitle] = useState('')
  const [metaDescription, setMetaDescription] = useState('')
  const [uploadProgress, setUploadProgress] = useState(false)
  const [imageResolutionWarning, setImageResolutionWarning] = useState(false)
  const [lang, setLang] = useState('en')
  const [reqInProgress, setReqInProgress] = useState(false)
  const [htmlEditorOpen, setHtmlEditorOpen] = useState(false)

  const isSafeEmpty = (str) => {
    if (!str) return true
    if (str.trim().length === 0) return true
    return false
  }

  const isIncorrect = (activeState) => {
    switch (activeState) {
      case 0:
        if (isSafeEmpty(title)) return true
        break
      case 1:
        if (isSafeEmpty(content)) return true
        break
      case 2:
        if (!date) return true
        if (isSafeEmpty(tags)) return true
        if (isSafeEmpty(slug)) return true
        if (isSafeEmpty(metaTitle)) return true
        if (isSafeEmpty(metaDescription)) return true
        break
      default:
    }
    return false
  }

  const triggerfileUpload = () => {
    fileUploadRef.current.click()
  }
  const fileUpladed = async (event) => {
    setImage(event.target.files[0])
    setUploadProgress(true)
    setImageResolutionWarning(false)
    try {
      const response = await uploadImage(event.target.files[0])
      setImageUrl(response.url)
      setImageThumbnailUrl(response.thumbnailUrl)
    } catch (e) {
    } finally {
      setUploadProgress(false)
    }
  }

  const autoGenerateSlug = () => {
    setSlug(slugify(title, slugifyConf))
  }

  const autoGenerateMetaTitle = () => {
    setMetaTitle(title)
  }

  const onImgLoad = (e) => {
    const height = e.target.offsetHeight
    const width = e.target.offsetWidth

    const resolution = width / height

    if (resolution >= 1.45 && resolution <= 1.55) {
      setImageResolutionWarning(false)
    } else {
      setImageResolutionWarning(true)
    }
  }

  const save = async () => {
    setReqInProgress(true)
    const blog = {
      title,
      content,
      metaTitle,
      lang,
      blogDate: date,
      banner: imageUrl,
      metaDescription,
      thumbnail: imageThumbnailUrl,
      tags: tags.split(',').map((t) => t.trim()),
      slug: slugify(slug, slugifyConf),
    }

    try {
      const response = await addBlog(blog)
      navigate(`/blogs/${response._id}`)
    } catch (e) {
      if (e?.response?.data?.message) {
        setSnackbarConf({
          open: true,
          message: e?.response?.data?.message,
        })
      }
    }
    setReqInProgress(false)
  }
  return (
    <>
      {reqInProgress && <Loader />}
      <Container>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            my: 2,
          }}
        >
          <Typography variant="h5" color="initial">
            New Blog
          </Typography>

          <Button
            onClick={save}
            disabled={
              activeState !== 2 || isIncorrect(activeState) || reqInProgress
            }
            variant="contained"
          >
            Save
          </Button>
        </Box>
        <Box sx={{ my: 2 }}>
          <Stepper activeStep={activeState} alternativeLabel={isMobile()}>
            <Step>
              <StepLabel>Add Title & Banner Image</StepLabel>
            </Step>
            <Step>
              <StepLabel>Add Content</StepLabel>
            </Step>
            <Step>
              <StepLabel>Add Meta info & Other Details</StepLabel>
            </Step>
          </Stepper>
        </Box>
        <Card>
          <CardContent>
            {activeState === 0 && (
              <React.Fragment>
                <FormItem label={'Title'} required={true}>
                  <TextField
                    multiline
                    fullWidth
                    sx={{ borderRadius: '20px' }}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    InputProps={{
                      sx: {
                        borderRadius: '24px',
                      },
                    }}
                    inputProps={{
                      style: {
                        fontWeight: 'bold',
                        fontSize: '22px',
                      },
                    }}
                  ></TextField>
                </FormItem>

                <FormItem>
                  <Card variant="outlined">
                    <CardContent>
                      {!image && (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100px',
                          }}
                        >
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={triggerfileUpload}
                          >
                            Upload Banner Image
                          </Button>
                        </Box>
                      )}
                      {image && (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            height: '300px',
                          }}
                        >
                          <Box>
                            {imageUrl && !uploadProgress && (
                              <>
                                <Box
                                  sx={{
                                    height: '200px',
                                  }}
                                >
                                  <img
                                    onLoad={onImgLoad}
                                    src={imageUrl}
                                    alt="sample"
                                    style={{
                                      height: '100%',
                                      borderRadius: '10px',
                                      boxShadow:
                                        'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                                    }}
                                  />
                                </Box>

                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    my: 1,
                                  }}
                                >
                                  <IconButton
                                    aria-label=""
                                    onClick={triggerfileUpload}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Box>
                                {imageResolutionWarning && (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      my: 1,
                                    }}
                                  >
                                    <Alert severity="warning" color="warning">
                                      Image resolution is not correct. Keep the
                                      image resolution 3:2
                                    </Alert>
                                  </Box>
                                )}
                                {!imageResolutionWarning && (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      my: 1,
                                    }}
                                  >
                                    <Alert severity="success" color="success">
                                      Resolution looks good! click next
                                    </Alert>
                                  </Box>
                                )}
                              </>
                            )}
                            {uploadProgress && (
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <CircularProgress />
                              </Box>
                            )}
                          </Box>
                        </Box>
                      )}
                    </CardContent>
                  </Card>
                </FormItem>
              </React.Fragment>
            )}

            {activeState === 1 && (
              <React.Fragment>
                {isEmpty(content) && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setHtmlEditorOpen(true)}
                    >
                      Add Html
                    </Button>
                  </Box>
                )}
                {!isEmpty(content) && (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setHtmlEditorOpen(true)}
                      >
                        Edit Html
                      </Button>
                    </Box>
                    <HtmlViewer html={content} />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setHtmlEditorOpen(true)}
                      >
                        Edit Html
                      </Button>
                    </Box>
                  </>
                )}
                <HtmlEditor
                  open={htmlEditorOpen}
                  html={content}
                  onChange={(e) => {
                    setContent(e)
                    setHtmlEditorOpen(false)
                  }}
                  onClose={() => setHtmlEditorOpen(false)}
                />
              </React.Fragment>
            )}

            {activeState === 2 && (
              <React.Fragment>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={4}>
                    <FormItem label={'Blog Date'} required={true}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <FormControl fullWidth>
                          <DatePicker
                            value={date}
                            onChange={(e) => setDate(e)}
                          />
                        </FormControl>
                      </LocalizationProvider>
                    </FormItem>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <FormItem label={'Tags'} required={true}>
                      <TextField
                        fullWidth
                        value={tags}
                        onChange={(e) => setTags(e.target.value)}
                        helperText="Comma separated values e.g. Oral Health, Periodontics"
                      ></TextField>
                    </FormItem>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <FormItem label={'Blog Language'} required={true}>
                      <Select
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={lang}
                        onChange={(e) => setLang(e.target.value)}
                      >
                        <MenuItem value={'en'}>English</MenuItem>
                        <MenuItem value={'hi'}>Hindi</MenuItem>
                      </Select>
                    </FormItem>
                  </Grid>
                </Grid>

                <FormItem label={'Slug'} required={true}>
                  <FormControl variant="outlined" fullWidth>
                    <OutlinedInput
                      fullWidth
                      id="outlined-adornment-password"
                      type={'text'}
                      value={slug}
                      onChange={(e) => setSlug(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={autoGenerateSlug}
                            onMouseDown={autoGenerateSlug}
                            edge="end"
                          >
                            <AutorenewIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </FormItem>
                <FormItem label={'Meta Title'} required={true}>
                  <FormControl variant="outlined" fullWidth>
                    <OutlinedInput
                      fullWidth
                      id="outlined-adornment-password"
                      type={'text'}
                      value={metaTitle}
                      onChange={(e) => setMetaTitle(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={autoGenerateMetaTitle}
                            onMouseDown={autoGenerateMetaTitle}
                            edge="end"
                          >
                            <AutorenewIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </FormItem>

                <FormItem label={'Meta Description'} required={true}>
                  <TextField
                    fullWidth
                    multiline
                    value={metaDescription}
                    onChange={(e) => setMetaDescription(e.target.value)}
                    helperText={
                      metaDescription?.length
                        ? `Character Count : ${metaDescription.length}`
                        : ''
                    }
                  ></TextField>
                </FormItem>
              </React.Fragment>
            )}

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                onClick={() => setActiveState((p) => p - 1)}
                disabled={activeState === 0}
              >
                Back
              </Button>
              {activeState !== 2 && (
                <Button
                  onClick={() => setActiveState((p) => p + 1)}
                  disabled={isIncorrect(activeState)}
                >
                  Next
                </Button>
              )}
            </Box>
            <input
              ref={fileUploadRef}
              style={{ display: 'none' }}
              type="file"
              onChange={(e) => fileUpladed(e)}
            />
          </CardContent>
        </Card>
      </Container>
      <Snackbar
        open={snackbarConf.open}
        autoHideDuration={6000}
        onClose={() => setSnackbarConf({ open: false })}
        message={snackbarConf.message}
      />
    </>
  )
}

function isEmpty(value) {
  return (
    value === undefined || value === null || value === '' || value.trim() === ''
  )
}
