import ReactCrop, { Crop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { useState, useRef } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Box,
  Typography,
  Slider,
  TextField,
  Stack,
  IconButton,
} from '@mui/material'
import { useEffect } from 'react'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import isMobile from 'is-mobile'
import RotateRightIcon from '@mui/icons-material/RotateRight'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'

export default function ImageRotator({ editImage, onChange, onCancel }) {
  const imageRef = useRef(null)
  const [tempImage, setTempImage] = useState(null)
  useEffect(() => {
    setTempImage(editImage)
  }, [editImage])

  const [angle, setAngle] = useState(0)

  const applyRotateImage = () => {
    getRotatedImage(angle).then((res) => {
      onChange(res)
    })
  }

  const rotateP90 = (value) => {
    setAngle((v) => {
      getRotatedImage(v + 90).then((res) => {
        setTempImage(res)
      })
      return v + 90
    })
  }
  const rotateN90 = (value) => {
    setAngle((v) => {
      getRotatedImage(v - 90).then((res) => {
        setTempImage(res)
      })
      return v - 90
    })
  }

  const getStyle = () => {
    return {
      width: '100%',
      height: 'auto',
    }
  }

  function getRotatedImage(_angle) {
    return new Promise((resolve, reject) => {
      if (_angle % 360 === 0) return resolve(editImage)

      const image = new Image()
      image.onload = () => {
        const canvas = document.createElement('canvas')
        if (_angle % 180 === 0) {
          canvas.width = image.naturalWidth
          canvas.height = image.naturalHeight
        } else {
          canvas.width = image.naturalHeight
          canvas.height = image.naturalWidth
        }

        const ctx = canvas.getContext('2d')
        ctx.translate(canvas.width / 2, canvas.height / 2)
        ctx.rotate((_angle * Math.PI) / 180)
        ctx.translate(-image.naturalWidth / 2, -image.naturalHeight / 2)

        ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight)
        resolve(canvas.toDataURL('image/jpeg'))
      }

      image.onerror = (error) => {
        reject('Failed to load image: ' + error.message)
      }

      image.src = editImage
    })
  }

  const download = () => {
    // const adjustedDataUrl = getAdjustedImage()
    // const downloadLink = document.createElement('a')
    // downloadLink.href = adjustedDataUrl
    // downloadLink.download = 'cropped-image.jpg'
    // document.body.appendChild(downloadLink)
    // downloadLink.click()
    // document.body.removeChild(downloadLink)
  }

  const reset = () => {
    setAngle(0)
  }

  return (
    <Box
      sx={{
        width: isMobile() ? '100%' : '90%',
        display: 'flex',
        flexDirection: isMobile() ? 'column' : 'row',
      }}
    >
      <Box
        sx={{
          width: isMobile() ? '100%' : '50%',
        }}
      >
        {tempImage && (
          <img ref={imageRef} src={tempImage} style={getStyle()} alt="edit" />
        )}
        {!tempImage && (
          <img ref={imageRef} src={editImage} style={getStyle()} alt="edit" />
        )}
      </Box>
      <Box
        sx={{
          padding: '20px 0px',
          width: isMobile() ? '100%' : '50%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        className="toolkit"
      >
        <Box
          sx={{
            marginBottom: isMobile() ? '10px' : '15px',
            width: isMobile() ? '100%' : '50%',
          }}
        >
          <Stack
            spacing={4}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <IconButton
              sx={{
                padding: '30px',
                background: 'rgba(0, 0, 0, 0.1)',
              }}
              onClick={() => rotateN90()}
            >
              <RotateLeftIcon
                sx={{
                  fontSize: '40px',
                }}
              />
            </IconButton>
            <IconButton
              sx={{
                padding: '30px',
                background: 'rgba(0, 0, 0, 0.1)',
              }}
              onClick={() => rotateP90()}
            >
              <RotateRightIcon
                sx={{
                  fontSize: '40px',
                }}
              />
            </IconButton>
          </Stack>
        </Box>

        <Button
          sx={{
            marginBottom: isMobile() ? '10px' : '15px',
            marginTop: '10px',
            width: isMobile() ? '100%' : '50%',
          }}
          variant="contained"
          onClick={() => applyRotateImage()}
        >
          Apply Adjustments
        </Button>
        <Button
          sx={{
            marginBottom: isMobile() ? '10px' : '15px',
            marginTop: '10px',
            width: isMobile() ? '100%' : '50%',
            color: 'gray',
          }}
          onClick={() => onCancel()}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  )
}
