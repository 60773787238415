import ReactCrop, { Crop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { useState, useRef } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Box,
  Typography,
  Slider,
  TextField,
  Stack,
  IconButton,
} from '@mui/material'
import { useEffect } from 'react'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import isMobile from 'is-mobile'
import RotateRightIcon from '@mui/icons-material/RotateRight'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import { grey } from '@mui/material/colors'

export default function SimpleImage({ image }) {
  return (
    <Box
      sx={{
        width: isMobile() ? '100%' : '90%',
        display: 'flex',
        flexDirection: isMobile() ? 'column' : 'row',
      }}
    >
      <Box
        sx={{
          width: isMobile() ? '100%' : '50%',
        }}
      >
        <img
          src={image}
          style={{
            width: '100%',
            height: 'auto',
          }}
          alt="edit"
        />
      </Box>
      <Box
        sx={{
          padding: '20px 0px',
          width: isMobile() ? '100%' : '50%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        className="toolkit"
      >
        <Typography variant="h5" sx={{ color: grey[500], textAlign: 'center' }}>
          When everything is ready, click "Upload Image"
        </Typography>
        <Typography variant="h5" sx={{ color: grey[500], textAlign: 'center' }}>
          if you want to start over, click "Reset"
        </Typography>
      </Box>
    </Box>
  )
}
